import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getAllAddressBookThunk,
  createThunk,
  updateThunk,
  deleteAddressBookThunk,
  searchThunk,
} from "./addressBookThunk";

const initialState = {
  loading: false,
  allAddressBook: [],
  seachAddressBook: [],
  mess: null,
  isLogged: false,
};

const addressBookSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    bankAction: (state, action) => {
      // removeAllLocalStorage();
      // deleteAllCookies();
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllAddressBookThunk.fulfilled, (state, action) => {
      const { payload } = action;
      state.allAddressBook = payload?.data?.data;
    });
    builder.addCase(searchThunk.fulfilled, (state, action) => {
      const { payload } = action;
      if (payload?.data.status === 200) {
        state.seachAddressBook = payload?.data?.data;
      }
    });
    builder.addCase(deleteAddressBookThunk.fulfilled, (state, action) => {
      state.loading = !state.loading;
    });
    builder.addCase(createThunk.fulfilled, (state, action) => {
      state.loading = !state.loading;
    });
    builder.addCase(updateThunk.fulfilled, (state, action) => {
      state.loading = !state.loading;
    });
    // builder.addCase(loginThunk.fulfilled, (state, action) => {
    //   console.log("vao day chu1");
    //   const { payload } = action;
    //   console.log(
    //     "🚀 ~ file: addressBookSlice.js ~ line 45 ~ builder.addCase ~ payload",
    //     payload
    //   );
    //   const data = payload?.data?.data ?? null;
    //   const token = data?.Token ?? null;
    //   if (token) {
    //     setCookie(COOKIE_ITEMS_NAME.ACCESS_TOKEN, token, 1);
    //     state.isLogged = true;
    //   }
    //   state.auth = data;
    //   state.loading = false;
    //   state.mess = null;
    // });
    // builder.addCase(loginThunk.rejected, (state, action) => {
    //   console.log("vao day chu");
    //   state.loading = false;
    //   state.mess = "Sai tên đăng nhập hoặc mật khẩu !";
    // });
  },
});

export const { addressBookAction } = addressBookSlice.actions;
export default addressBookSlice.reducer;
