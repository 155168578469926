import { TRACKING } from "../endPoints";
import httpClientPortal from "../httpClientPortal";

export async function getTrackingByOrderId(params) {
  try {
    return httpClientPortal.get(TRACKING.getTrackingByOrderId, { params });
  } catch (err) {
    return Promise.reject(err);
  }
}
export async function creatTracking(params) {
  try {
    return httpClientPortal.post(TRACKING.creatTracking, params);
  } catch (err) {
    return Promise.reject(err);
  }
}
export async function updatTracking(params) {
  try {
    return httpClientPortal.put(TRACKING.updatTracking, params);
  } catch (err) {
    return Promise.reject(err);
  }
}
export async function deletTracking(params) {
  try {
    return httpClientPortal.delete(TRACKING.deletTracking + params);
  } catch (err) {
    return Promise.reject(err);
  }
}
