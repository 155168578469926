import { createAsyncThunk } from "@reduxjs/toolkit";
import { createPaymentsSevice, deletePaymentsSevice, getPaymentsSevice, updatePaymentsSevice } from "../../services/Payments";

export const getPayments = createAsyncThunk(
    "get/Payments",
    async (payload, thunkAPI) => {
      try {
        const response = await getPaymentsSevice(payload);
        return response;
      } catch (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  );
export const createPayments = createAsyncThunk(
    "create/Payments",
    async (payload, thunkAPI) => {
      try {
        const response = await createPaymentsSevice(payload);
        return response;
      } catch (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  );
export const updatePayments = createAsyncThunk(
    "update/Payments",
    async (payload, thunkAPI) => {
      try {
        const response = await updatePaymentsSevice(payload);
        return response;
      } catch (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  );
export const deletePayments = createAsyncThunk(
    "delete/Payments",
    async (Id, thunkAPI) => {
      try {
        const response = await deletePaymentsSevice(Id);
        return response;
      } catch (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  );
  