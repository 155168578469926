import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  createShopPartnerService,
  deleteShopPartnerService,
  getAllConditionSwithOrder,
  getConditionSwithOrderByUserId,
  searchShopPartnerService,
  updateShopPartnerService,
} from "../../services/ShopPartner";

export const searchShopPartner = createAsyncThunk(
  "search/shopPartner",
  async (payload, thunkAPI) => {
    try {
      const response = await searchShopPartnerService(payload);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const createShopPartner = createAsyncThunk(
  "create/shopPartner",
  async (payload, thunkAPI) => {
    try {
      const response = await createShopPartnerService(payload);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const updateShopPartner = createAsyncThunk(
  "update/shopPartner",
  async (payload, thunkAPI) => {
    try {
      const response = await updateShopPartnerService(payload);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const deleteShopPartner = createAsyncThunk(
  "delete/shopPartner",
  async (Id, thunkAPI) => {
    try {
      const response = await deleteShopPartnerService(Id);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const getAllConditionForSwichOrderThunk = createAsyncThunk(
  "get/allCondition",
  async (_, thunkAPI) => {
    try {
      const response = await getAllConditionSwithOrder();
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const getConditionForSwichOrderByUserThunk = createAsyncThunk(
  "get/conditionByUser",
  async (params, thunkAPI) => {
    try {
      const response = await getConditionSwithOrderByUserId(params);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
