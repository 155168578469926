import {
  STORE_MANAGER,
  STORE_MANAGER_GROUP,
  STORE_GROUP_USER,
} from "../endPoints";
import httpClient from "../httpClientPortal";

export async function getAllPermissionService(payload) {
  try {
    return httpClient.get(STORE_MANAGER.getAllPermission, payload);
  } catch (err) {
    return Promise.reject(err);
  }
}

export async function getByNamePermissionService(payload) {
  try {
    return httpClient.get(STORE_MANAGER.getByNamePermission, payload);
  } catch (err) {
    return Promise.reject(err);
  }
}
export async function createPermissionService(payload) {
  try {
    return httpClient.post(STORE_MANAGER.createPermission, payload);
  } catch (err) {
    return Promise.reject(err);
  }
}

export async function updatePermissionService(payload) {
  try {
    return httpClient.put(STORE_MANAGER.updatePermission, payload);
  } catch (err) {
    return Promise.reject(err);
  }
}

export async function deletePermissionService(Id) {
  try {
    return httpClient.delete(`${STORE_MANAGER.deletePermission}/${Id}`);
  } catch (err) {
    return Promise.reject(err);
  }
}

export async function getAllGroupUserService(payload) {
  try {
    return httpClient.get(STORE_GROUP_USER.getAllGroupUser, payload);
  } catch (err) {
    return Promise.reject(err);
  }
}

export async function createGroupUserService(payload) {
  try {
    return httpClient.post(STORE_GROUP_USER.createGroupUser, payload);
  } catch (err) {
    return Promise.reject(err);
  }
}

export async function updateGroupUserService(payload) {
  try {
    return httpClient.put(STORE_GROUP_USER.updateGroupUser, payload);
  } catch (err) {
    return Promise.reject(err);
  }
}

export async function deleteGroupUserService(Id) {
  try {
    return httpClient.delete(`${STORE_GROUP_USER.deleteGroupUser}/${Id}`);
  } catch (err) {
    return Promise.reject(err);
  }
}

export async function getAllPermissionGroupService(payload) {
  try {
    return httpClient.get(STORE_MANAGER_GROUP.getAllPermission, payload);
  } catch (err) {
    return Promise.reject(err);
  }
}
export async function createPermissionGroupService(payload) {
  try {
    return httpClient.post(STORE_MANAGER_GROUP.createPermission, payload);
  } catch (err) {
    return Promise.reject(err);
  }
}
export async function updatePermissionGroupService(payload) {
  try {
    return httpClient.put(STORE_MANAGER_GROUP.updatePermission, payload);
  } catch (err) {
    return Promise.reject(err);
  }
}
export async function deletePermissionGroupService(payload) {
  try {
    return httpClient.delete(STORE_MANAGER_GROUP.deletePermission + payload);
  } catch (err) {
    return Promise.reject(err);
  }
}
