import React, { Suspense } from "react";
import { Spin } from "antd";
import { Routes, Route } from "react-router-dom";
import NotFoundScreen from "./pages/commons/notFound";
import { authRoutes, PrivateRoutes, publicRoutes } from "./routes";
import "./themes/scss/root.scss";
// import "antd/dist/antd.min.css";
// import "primeflex/primeflex.css";
function App() {
  return (
    <Suspense
      fallback={
        <div className="loading__suspense">
          <Spin size="large" tip="Đang xử lý dữ liệu..." />{" "}
        </div>
      }
    >
      <Routes>
        {/* TODO: define public routes*/}
        {publicRoutes.map((el, i) => (
          <Route path={el.path} key={i} element={<el.component />} />
        ))}
        {/* TODO: define private routes*/}
        <Route path="/" element={<PrivateRoutes />}>
          {authRoutes.map((el, i) => (
            <Route path={el.path} key={i} element={<el.component />} />
          ))}
        </Route>
        <Route path="*" element={<NotFoundScreen />} />
      </Routes>
    </Suspense>
  );
}

export default App;
