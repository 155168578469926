import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import authReducers from "./auth/authSlice";
import bankReducers from "./bank/bankSlice";
import addressBookReducers from "./addressBook/addressBookSlice";
import userReducers from "./user/userSlice";
import roomReducer from "./room/roomSlice";
import departmentReducers from "./department/departmentSlice";
import addressReducers from "./address/addressSlice";
import orderReducer from "./order/orderSlice";
import registerReduce from "./register/registerSlice";
import storeManagerReducer from "./storeManager/storeManagerSlice";
import dashboardReducer from "./dashboard/dashboardSlice";
import shippingUnitReducer from "./shippingUnit/shippingUnitSlice";
import webHookReducer from "./webHook/webHookSlice";
import PaymentReducer from "./Payments/PaymentSlice";
import ShopPartNerReducer from "./ShopPartner/shopPartnerSlice";
import partnerConnectionReducer from "./partnerConnection/partnerConnectionSlice";
import serviceSlice from "./service/ServiceSlice";
import trackingSlice from "./tracking/TrackingSlice";
import LogSearchSlice from "./LogSearch/LogSearchSlice";

export const store = configureStore({
  reducer: {
    authStore: authReducers,
    users: userReducers,
    room: roomReducer,
    dashboardStore: dashboardReducer,
    shippingUnitStore: shippingUnitReducer,
    addressBook: addressBookReducers,
    bankStore: bankReducers,
    department: departmentReducers,
    address: addressReducers,
    order: orderReducer,
    register: registerReduce,
    storeManager: storeManagerReducer,
    webHook: webHookReducer,
    PaymentStore: PaymentReducer,
    ShopPartNer: ShopPartNerReducer,
    partnerConnection: partnerConnectionReducer,
    serviceStore: serviceSlice,
    trackingStore: trackingSlice,
    LogSearchStore: LogSearchSlice,
  },
  middleware: [
    ...getDefaultMiddleware({
      serializableCheck: false,
    }),
  ],
});
