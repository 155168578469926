import { REGISTER_URL } from "../endPoints";
import httpClient from "../httpClientPortal";

export async function generateOtpSevice(payload) {
  try {
    return httpClient.post(REGISTER_URL.generateOTP, payload);
  } catch (err) {
    return Promise.reject(err);
  }
}

export async function checkOtpSevice(payload) {
  try {
    return httpClient.post(REGISTER_URL.checkOTP, payload);
  } catch (err) {
    return Promise.reject(err);
  }
}

export async function registerSevice(payload) {
  try {
    return httpClient.post(REGISTER_URL.register, payload);
  } catch (err) {
    return Promise.reject(err);
  }
}
