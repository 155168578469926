import { ADDRESS_BOOK_URL } from "../endPoints";
import httpClientPortal from "../httpClientPortal";

export async function getAlladdressBookName(params) {
  try {
    return httpClientPortal.get(ADDRESS_BOOK_URL.getAlladdressBook, params);
  } catch (err) {
    return Promise.reject(err);
  }
}
export async function create(params) {
  try {
    return httpClientPortal.post(ADDRESS_BOOK_URL.create, params);
  } catch (err) {
    return Promise.reject(err);
  }
}
export async function update(params) {
  try {
    return httpClientPortal.put(ADDRESS_BOOK_URL.update, params);
  } catch (err) {
    return Promise.reject(err);
  }
}
export async function deleteaddressBook(Id) {
  try {
    return httpClientPortal.delete(ADDRESS_BOOK_URL.delete + Id);
  } catch (err) {
    return Promise.reject(err);
  }
}
export async function search(params) {
  try {
    return httpClientPortal.get(ADDRESS_BOOK_URL.search, { params });
  } catch (err) {
    return Promise.reject(err);
  }
}
