import { PROVIDER_PARTNER_IDS } from ".";

//TODO: DEFINE MAPPING FIELD TOKEN
export const MAPPING_TOKEN_FIELDS = {
  VIETTEL_POST: [
    {
      key: "vt_partner_token",
      mappingField: "vtpPartnerToken",
      timeOut: "vtpPartnerTokenEx",
      fieldConfig: "TokenConnect",
    },
    {
      key: "vt_customer_token",
      mappingField: "vtpCustomerToken",
      timeOut: "vtpCustomerTokenEx",
      fieldConfig: "PartnerToken",
    },
    {
      key: "vt_default_token",
      mappingField: "vtpDefaultToken",
      timeOut: "vtpDefaultTokenEx",
      fieldConfig: "DefaultToken",
    },
    {
      key: "vt_partner_token_expried",
      mappingField: "vtpPartnerTokenEx",
      timeOut: "vtpPartnerTokenEx",
    },
    {
      key: "vt_customer_token_expried",
      mappingField: "vtpCustomerTokenEx",
      timeOut: "vtpCustomerTokenEx",
    },
    {
      key: "vt_default_token_expried",
      mappingField: "vtpDefaultTokenEx",
      timeOut: "vtpDefaultTokenEx",
    },
  ],
};

//TODO: DEFINE FIELDS SAVED OF PROVIDER
export const TOKEN_FIELDS_FOR_SAVE = [
  "DefaultToken",
  "TokenConnect",
  "PartnerToken",
];

export const PARTNER_TOKEN_TYPE = {
  CONNECTION: 1,
  DEFAULT: 2,
  CLIENT: 3,
};

export const MAPPING_TABLE_TOKEN = [
  {
    partnerId: PROVIDER_PARTNER_IDS.VIETTEL_POST,
    mapping: [
      { type: PARTNER_TOKEN_TYPE.CONNECTION, field: "vt_partner_token" },
      { type: PARTNER_TOKEN_TYPE.DEFAULT, field: "vt_default_token" },
      { type: PARTNER_TOKEN_TYPE.CLIENT, field: "vt_customer_token" },
    ],
  },
];

export const HEADER_CONFIG_OF_NTX_TEST = {
  username: "onexpress",
  password: "2UGvjtMr9KX5cATK",
  partner_id: 424,
  isAcceptTokenHeader: false,
};

export const HEADER_CONFIG_OF_NTX = {
  username: "onexpress",
  password: "FLuGuaYP8fERGaaU",
  partner_id: 28823,
  isAcceptTokenHeader: false,
};

export const HEADER_CONFIG_OF_NTL_TEST = {
  username: "onexpress",
  password: "e73bc5fd114c18985badff81bcfba7cf",
  partner_id: 123895,
  isAcceptTokenHeader: false,
};

export const HEADER_CONFIG_OF_NTL = {
  username: "onexpress",
  password: "087403e4691bbdb440bc5b560e9b4b55",
  partner_id: 224065,
  isAcceptTokenHeader: false,
};

export const HEADER_CONFIG_OF_VIETTEL_POST = {
  isAcceptTokenHeader: true,
  tokenFieldRequest: "Token",
};

const HEADER_CONFIG_DEV = {
  NTX: { ...HEADER_CONFIG_OF_NTX_TEST },
  NTL: { ...HEADER_CONFIG_OF_NTL_TEST },
  VIETTEL_POST: { ...HEADER_CONFIG_OF_VIETTEL_POST },
};

const HEADER_CONFIG_PRODUCTION = {
  NTX: { ...HEADER_CONFIG_OF_NTX },
  NTL: { ...HEADER_CONFIG_OF_NTL },
  VIETTEL_POST: { ...HEADER_CONFIG_OF_VIETTEL_POST },
};

const OAUTH2_CONFIG_VIETTEL = {
  username: "0988581102",
  password: "NTX@1919",
  authUrlPartnerConnect: "https://partner.viettelpost.vn/v2/user/Login",
  authUrlPartnerConnectMethod: "POST",
};

export const OAUTH2_CONFIGS = [
  { ProviderId: 3, Name: "VTP", config: OAUTH2_CONFIG_VIETTEL },
];

const devMode = process.env.REACT_APP_MODE;
const isDevMode = devMode === "dev";
export const HEADER_CONFIG = isDevMode
  ? HEADER_CONFIG_DEV
  : HEADER_CONFIG_PRODUCTION;
