import { DEPARTMENT_URL } from "../endPoints";
import httpClient from "../httpClientPartner";
import api from "../httpClientPortal";
const headers = {
  username: "onexpress",
  password: "FLuGuaYP8fERGaaU",
  partner_id: "28823",
};

export async function getDepartment(params) {
  try {
    return httpClient.get(DEPARTMENT_URL.search, { params: params });
  } catch (err) {
    return Promise.reject(err);
  }
}

export async function createDepartment(payload) {
  try {
    return httpClient.post(DEPARTMENT_URL.create, payload);
  } catch (err) {
    return Promise.reject(err);
  }
}

export async function updateDepartment(payload) {
  try {
    return httpClient.put(DEPARTMENT_URL.update, payload);
  } catch (err) {
    return Promise.reject(err);
  }
}

export async function deleteDepartment(params) {
  try {
    return httpClient.post(DEPARTMENT_URL.delete,params,{headers})
  } catch (err) {
    return Promise.reject(err);
  }
}

export async function getStatusService(params) {
  try {
    return api.get(DEPARTMENT_URL.status, params);
  } catch (err) {
    return Promise.reject(err);
  }
}