import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  checkOtpSevice,
  generateOtpSevice,
  registerSevice,
} from "../../services/register";

export const generateOtp = createAsyncThunk(
  "register/generateOTP",
  async (payload, thunkAPI) => {
    try {
      const response = await generateOtpSevice(payload);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const checkOtp = createAsyncThunk(
  "register/checkOTP",
  async (payload, thunkAPI) => {
    try {
      const response = await checkOtpSevice(payload);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const register = createAsyncThunk(
  "Authentication/Register",
  async (payload, thunkAPI) => {
    try {
      const response = await registerSevice(payload);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
