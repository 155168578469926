import { createSlice } from "@reduxjs/toolkit";
import { generateOtp } from "./registerThunk";
const initialState = {
  phoneNumber: null,
};

const registerSlice = createSlice({
  name: "register",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(generateOtp.fulfilled, (state, action) => {
      console.log(action);
      state.phoneNumber = action.meta.arg.PhoneNumber;
    });
  },
});

export const {} = registerSlice.actions;
export default registerSlice.reducer;
