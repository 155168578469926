import { createSlice } from "@reduxjs/toolkit";
import { getPayments } from "./PaymentThunk";
const initialState = {
  data: [],
  pagination: {},
  defaultValue: {},
  loading: false,
  isOpen: false,
  isUpdate: false,
};

const PaymentSlice = createSlice({
  name: "register",
  initialState,
  reducers: {
    openFormPayment: (state, action) => {
      state.isOpen = true;
      state.isUpdate = action?.payload?.isUpdate ?? false;
      state.defaultValue = action?.payload?.defaultValue ?? {};
    },
    closeModalPayment: (state, actions) => {
      state.isOpen = false;
      state.isUpdate = false;
      state.defaultValue = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPayments.fulfilled, (state, action) => {
      state.data = action.payload.data.data;
      state.pagination = action?.payload?.data?.pagination??[];
      state.loading = false;
    });
    builder.addCase(getPayments.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getPayments.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export const { openFormPayment, closeModalPayment } = PaymentSlice.actions;
export default PaymentSlice.reducer;
