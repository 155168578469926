import { AUTHENTICATION_TYPE_OF_PROVIDER, PROVIDER_PARTNER_IDS } from ".";
import { mappingRequest, setCookie } from "../utils";
import {
    HEADER_CONFIG,
    MAPPING_TOKEN_FIELDS,
    OAUTH2_CONFIGS,
} from "./partnerConfig";

export const requestOptionsNTXCreateOrder = {
    Endpoint: "https://apiws.ntx.com.vn/v1/bill/create-by-location",
    Method: "POST",
    RequestType: "data",
    PartnerId: 1,
};
export const requestOptionsNTXGetCalcFee = {
    Endpoint: "https://apiws.ntx.com.vn/v1/bill/calc-fee",
    Method: "POST",
    RequestType: "data",
    PartnerId: 1,
};

const MAPPING_CONFIGS = [
    { PartnerId: PROVIDER_PARTNER_IDS.NTX, headers: HEADER_CONFIG.NTX },
    { PartnerId: PROVIDER_PARTNER_IDS.NTL, headers: HEADER_CONFIG.NTL },
    {
        PartnerId: PROVIDER_PARTNER_IDS.VIETTEL_POST,
        headers: HEADER_CONFIG.VIETTEL_POST,
    },
];

export const findHeaderConfig = (providerId) => {
    return MAPPING_CONFIGS.find((el) => el.PartnerId === providerId);
};

export const findAuthTypeForLoginConnect = (providerId) => {
    return AUTHENTICATION_TYPE_OF_PROVIDER.find(
        (el) => el.ProviderId === providerId
    ).Type;
};

const devMode = process.env.REACT_APP_MODE;
const reactAppHost = process.env.REACT_APP_PORTAL_HOST_URL;
const isDevMode = devMode === "dev";
const endPointTest = {
    NTX: "https://apisandbox.ntx.com.vn",
    NTL: "https://apisandbox.ntlogistics.vn",
    VIETTEL_POST: "https://partner.viettelpost.vn",
};
const endPointProduction = {
    NTX: "https://apiws.ntx.com.vn",
    NTL: "https://apiws.ntlogistics.vn",
    VIETTEL_POST: "https://partner.viettelpost.vn",
};

const endpoitn = isDevMode ? endPointTest : endPointProduction;

export const REQUEST_OPTIONS_CREATE_ORDER = [
    {
        Endpoint: `${endpoitn.NTX}/v1/bill/create-by-location`,
        Method: "POST",
        RequestType: "body",
        PartnerId: PROVIDER_PARTNER_IDS.NTX,
    },
    {
        Endpoint: `${endpoitn.NTL}/v2/bill/create`,
        Method: "POST",
        RequestType: "body",
        PartnerId: PROVIDER_PARTNER_IDS.NTL,
    },
    {
        Endpoint: `${reactAppHost}/api/ViettelPost/create`,
        Method: "POST",
        RequestType: "body",
        PartnerId: PROVIDER_PARTNER_IDS.VIETTEL_POST,
    },
];

export const REQUEST_OPTIONS_CALCULATE_FEE_ORDER = [
    {
        Endpoint: `${endpoitn.NTX}/v1/bill/calc-fee`,
        Method: "POST",
        RequestType: "body",
        PartnerId: PROVIDER_PARTNER_IDS.NTX,
    },
    {
        Endpoint: `${endpoitn.NTL}/v1/bill/calc-fee`,
        Method: "POST",
        RequestType: "body",
        PartnerId: PROVIDER_PARTNER_IDS.NTL,
    },
    {
        Endpoint: `${reactAppHost}/api/ViettelPost/CalculateFee`,
        Method: "POST",
        RequestType: "body",
        PartnerId: PROVIDER_PARTNER_IDS.VIETTEL_POST,
    },
];

export const REQUEST_OPTIONS_GET_SUITABLE_SERVICES_ORDER = [
    {
        Endpoint: `${reactAppHost}/api/ViettelPost/GetAllPrice`,
        Method: "POST",
        RequestType: "body",
        PartnerId: PROVIDER_PARTNER_IDS.VIETTEL_POST,
    },
];

export const selectProviderCreateOrder = (providerId) => {
    return REQUEST_OPTIONS_CREATE_ORDER.find(
        (el) => el.PartnerId === providerId
    );
};

export const selectProviderCalcFeeOrder = (providerId) => {
    return REQUEST_OPTIONS_CALCULATE_FEE_ORDER.find(
        (el) => el.PartnerId === providerId
    );
};

export const selectProviderGetSuitableServices = (providerId) => {
    return REQUEST_OPTIONS_GET_SUITABLE_SERVICES_ORDER.find(
        (el) => el.PartnerId === providerId
    );
};

const processingOauth2Vtp = async (connectConfig, providerConfig) => {
    let isSuccess = true;
    const paramsGetTokenClientPartner = {
        USERNAME: connectConfig.username,
        PASSWORD: connectConfig.password,
    };

    const paramsGetTokenClientOwner = {
        USERNAME: providerConfig.username,
        PASSWORD: providerConfig.password,
    };

    const endPointGetTokenClientPartner = {
        Endpoint: connectConfig?.authUrlPartnerConnect,
        Method: connectConfig?.authUrlPartnerConnectMethod,
        RequestType: "body",
    };

    const endPointGetTokenClientOwner = {
        Endpoint: providerConfig.AuthenUrl,
        Method: providerConfig.Method,
        RequestType: "body",
    };

    //TODO: get token partner connect
    const partnerToken = await mappingRequest(
        endPointGetTokenClientPartner,
        paramsGetTokenClientPartner
    );
    if (!partnerToken?.data?.data?.token) {
        isSuccess = false;
    }
    if (partnerToken?.data?.data?.token) {
        const clientPartnerToken = await mappingRequest(
            endPointGetTokenClientOwner,
            paramsGetTokenClientPartner,
            { headers: { Token: partnerToken?.data?.data?.token } }
        );
        const clientOwnerToken = await mappingRequest(
            endPointGetTokenClientOwner,
            paramsGetTokenClientOwner,
            { headers: { Token: partnerToken?.data?.data?.token } }
        );
        if (
            !clientPartnerToken?.data?.data?.token ||
            !clientOwnerToken?.data?.data?.token
        ) {
            isSuccess = false;
        }
        const payloadForSave = {
            vtpPartnerToken: partnerToken?.data?.data?.token,
            vtpCustomerToken: clientPartnerToken?.data?.data?.token,
            vtpDefaultToken: clientOwnerToken?.data?.data?.token,
            vtpPartnerTokenEx: partnerToken?.data?.data?.expired,
            vtpCustomerTokenEx: clientPartnerToken?.data?.data?.expired,
            vtpDefaultTokenEx: clientOwnerToken?.data?.data?.expired,
        };
        //TODO: MAPPING TOKEN FOR COOKIE DOCUMENT
        MAPPING_TOKEN_FIELDS.VIETTEL_POST.forEach((el) => {
            setCookie(el.key, payloadForSave[el.mappingField], {
                expired: payloadForSave[el.timeOut],
            });
        });
    }
    return isSuccess;
};

export const PROCESSING_OAUTH2_FUNCS = {
    3: processingOauth2Vtp, // VIETTEL POST
};

export const executeOauth2 = async (providerId, providerConfig) => {
    const configConnect = OAUTH2_CONFIGS.find(
        (el) => el.ProviderId === providerId
    )?.config;
    const func = PROCESSING_OAUTH2_FUNCS[providerId];
    const isSuccess = await func(configConnect, providerConfig);
    return isSuccess;
};
