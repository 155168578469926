import { createSlice } from "@reduxjs/toolkit";
import { changeStateValues } from "../../utils";
const initialState = {
  dataRoom: [],
  room: {},
  isToggle: false,
  isTogglePyment: false,
  pagination: {},
  title: "",
};

const roomSlice = createSlice({
  name: "room",
  initialState,
  reducers: {
    toggleDrawer: changeStateValues,
    getDataRoom: changeStateValues,
    setRoom:changeStateValues,
    dispatchRoom: (state, action) => {
      const index = state.dataRoom.findIndex((item) => {
        return item.key === action.payload.key;
      });
      if (index !== -1) {
        const newDataRoom = state.dataRoom.map((item, i) => {
          if (i === index) {
            return {...action.payload,stt:index+1,value:action.payload.reservationCode};
          }
          return { ...item };
        });
        return { ...state, dataRoom: newDataRoom };
      }
      const newUser = {
        ...action.payload,
        key: state.dataRoom.length + 1,
        stt:state.dataRoom.length + 1,
        value: action.payload.reservationCode,
      };
      return { ...state, dataRoom: [...state.dataRoom, newUser] };
    },
  },
});

export const { toggleDrawer, dispatchRoom, getDataRoom,setRoom } = roomSlice.actions;
export default roomSlice.reducer;
