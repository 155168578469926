import { createSlice } from "@reduxjs/toolkit";
import { COOKIE_ITEMS_NAME, LOCAL_STORAGE_ITEMS_NAME } from "../../constants";
import {
  deleteAllCookies,
  setCookie,
  removeAllLocalStorage,
  setLocalStorageItem,
} from "../../utils";
import { loginThunk } from "./authThunk";
import httpClient from "../../services/httpClientPortal";
import { onSuccessMessage } from "../../components/message";

const initialState = {
  auth: null,
  loading: false,
  isLogged: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logoutAction: (state, action) => {
      removeAllLocalStorage();
      // deleteCookie(COOKIE_ITEMS_NAME.ACCESS_TOKEN);
      deleteAllCookies();
      state.isLogged = false;
      delete httpClient.defaults.headers.common["apikey"];
    },
  },

  extraReducers: (builder) => {
    builder.addCase(loginThunk.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(loginThunk.fulfilled, (state, action) => {
      const { payload } = action;
      const data = payload?.data ?? null;
      const token = data?.token ?? null;
      if (token) {
        httpClient.defaults.headers.common["apikey"] = token;
      }
      const dataSaved = { ...data };
      delete dataSaved?.token;
      if (token) {
        setCookie(COOKIE_ITEMS_NAME.ACCESS_TOKEN, token, 1);
        setLocalStorageItem(
          LOCAL_STORAGE_ITEMS_NAME.AUTH_INFO,
          JSON.stringify(dataSaved)
        );
        state.isLogged = true;
      }
      state.auth = data;
      state.loading = false;
    });
    builder.addCase(loginThunk.rejected, (state, action) => {
      state.loading = false;
      onSuccessMessage("Sai tên đăng nhập hoặc mật khẩu !");
    });
  },
});

export const { logoutAction } = authSlice.actions;
export default authSlice.reducer;
