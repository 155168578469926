import { createAsyncThunk } from "@reduxjs/toolkit";
import { getLogSearchSevice } from "../../services/LogSearch";

export const getLogSearchThunk = createAsyncThunk(
    "get/LogSearch",
    async (payload, thunkAPI) => {
      try {
        const response = await getLogSearchSevice(payload);
        return response;
      } catch (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  );