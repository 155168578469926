import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getAllCity,
  getDistrictByCityId,
  getWards,
} from "../../services/address";

export const getCityThunk = createAsyncThunk(
  "address/getCity",
  async (payload, thunkAPI) => {
    try {
      const response = await getAllCity();
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const getDistrictThunk = createAsyncThunk(
  "/v1/loc/districts",
  async (payload, thunkAPI) => {
    try {
      const response = await getDistrictByCityId(payload.value);
      return {response,type:payload.type};
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const getWardsThunk = createAsyncThunk(
  "address/getWards",
  async (payload, thunkAPI) => {
    try {
      const response = await getWards(payload.value);
      return {response,type:payload.type};
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
