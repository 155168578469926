import { createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import {
  requestOptionsNTXCreateOrder,
  requestOptionsNTXGetCalcFee,
} from "../../constants/requestPartnerConfig";
import {
  checkAddressLocal,
  createOrder,
  createOrderLocal,
  destroyOder,
  getCalcFee,
  getCargocontentService,
  getOrderLocal,
} from "../../services/oder";
import { mappingRequest } from "../../utils";

export const getCalcFeeThunk = createAsyncThunk(
  "order/getCalcFeeThunk",
  async (payload, thunkAPI) => {
    try {
      const response = await mappingRequest(
        requestOptionsNTXGetCalcFee,
        payload
      );
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const createOrderThunk = createAsyncThunk(
  "order/getOrderThunk",
  async (payload, thunkAPI) => {
    try {
      // const response = await createOrder(payload);
      const response = await mappingRequest(
        requestOptionsNTXCreateOrder,
        payload
      );
      if (response.status === 200) {
        const result = response.data.data;
        thunkAPI.dispatch(
          createOrderLocalThunk([
            {
              ...result,
              bill_no: result.bill_code,
              estimated_deliver_time: moment(Date.now()).format("YYYY-MM-DD"),
              partner_id: 1,
              s_provinceId: payload.s_provinceId,
              s_districtId: payload.s_districtId,
              s_wardId: payload.s_wardId,
              r_provinceId: payload.r_provinceId,
              r_districtId: payload.r_districtId,
              r_wardId: payload.r_wardId,
              // cod_fee: payload.cod_amount,
            },
          ])
        );
      }
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const createOrderLocalThunk = createAsyncThunk(
  "order/createOrderLocalThunk",
  async (payload, thunkAPI) => {
    try {
      const response = await createOrderLocal(payload);
      if (response.status === 200) {
      }
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const getOrderLocalThunk = createAsyncThunk(
  "order/getOrderLocalThunk",
  async (payload, thunkAPI) => {
    try {
      const response = await getOrderLocal(payload);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const destroyThunk = createAsyncThunk(
  "order/destroyThunk",
  async (payload, thunkAPI) => {
    try {
      const response = await destroyOder(payload);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const checkAddressThunk = createAsyncThunk(
  "order/checkAddressThunk",
  async (payload, thunkAPI) => {
    try {
      const response = await checkAddressLocal(payload);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const getCargocontent = createAsyncThunk(
  "oder/getCargocontent",
  async (payload, thunkAPI) => {
    try {
      const response = await getCargocontentService();
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
