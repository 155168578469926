import { dashboardThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  payload: [],
};

const addressBookSlice = createSlice({
  name: "dasboad",
  initialState,
  reducers: {
    setPayload: (state, action) => {
      console.log(action);
      state.payload = action;
    },
  },
  extraReducers: (builder) => {},
});

export const { addressBookAction } = addressBookSlice.actions;
export default addressBookSlice.reducer;
