import {
  BankOutlined,
  BookOutlined,
  CaretRightOutlined,
  CarOutlined,
  ClusterOutlined,
  CreditCardOutlined,
  DropboxOutlined,
  FieldTimeOutlined,
  FileDoneOutlined,
  InsertRowLeftOutlined,
  LinkOutlined,
  MailOutlined,
  OrderedListOutlined,
  PieChartOutlined,
  SettingOutlined,
  ShopOutlined,
  ShoppingOutlined,
  UserOutlined,
} from "@ant-design/icons";
import React from "react";

function getItem(label, key, icon, path, children) {
  return {
    key,
    icon,
    children,
    label,
    path,
  };
}

const nav = [
  getItem("Dashboard", "1", <PieChartOutlined className="text-xl" />, "/"),
  // getItem(
  //   "Quản lý vận đơn",
  //   "2",
  //   <BankOutlined className="text-xl" />,
  //   "/order"
  // ),
  // getItem(
  //   "Cấu hình tài khoản",
  //   "sup1",
  //   <UserOutlined className="text-xl" />,
  //   "/user",
  //   [
  //     getItem(
  //       "Thông tin ngân hàng",
  //       "3",
  //       <InsertRowLeftOutlined />,
  //       "/user/bank"
  //     ),
  //     getItem(
  //       "Sổ địa chỉ",
  //       "4",
  //       <ClusterOutlined className="text-xl" />,
  //       "/user/address"
  //     ),
  //     getItem(
  //       "Đơn vị vận chuyển",
  //       "5",
  //       <CarOutlined className="text-xl" />,
  //       "/user/ShippingUnit"
  //     ),
  //     getItem(
  //       "Cấu hình WebHook",
  //       "6",
  //       <FileDoneOutlined className="text-xl" />,
  //       "/user/webhook"
  //     ),
  //   ]
  // ),

  // getItem(
  //   "Quản lý cửa hàng",
  //   "7",
  //   <ShopOutlined className="text-xl" />,
  //   "/store-manager"
  // ),
  getItem(
    "Partner Connection",
    "8",
    <LinkOutlined className="text-xl" />,
    "/partner-connection"
  ),
  // getItem(
  //   "Quản lý nhân viên",
  //   "9",
  //   <BookOutlined className="text-xl" />,
  //   "/user-management"
  // ),
  // getItem(
  //   "Hỗ trợ xử lý yêu cầu",
  //   "10",
  //   <DropboxOutlined className="text-xl" />,
  //   "/order-support"
  // ),
  getItem(
    "Quản lý shop partner",
    "11",
    <ShoppingOutlined className="text-xl" />,
    "/shop-partner"
  ),
  getItem("Dịch vụ", "12", <SettingOutlined className="text-xl" />, "/service"),
  getItem(
    "Hình thức thanh toán",
    "14",
    <CreditCardOutlined className="text-xl" />,
    "/Payments"
  ),
  getItem(
    "Cập nhật Webhook",
    "13",
    <CaretRightOutlined className="text-xl" />,
    "/function/run-webhook"
  ),
  // getItem(
  //   "Quản lý lịch trình",
  //   "14",
  //   <FieldTimeOutlined className="text-xl" />,
  //   "/logs-history"
  // ),
  // getItem(
  //   "Tạo đơn ngoài",
  //   "15",
  //   <FieldTimeOutlined className="text-xl" />,
  //   "/external-order"
  // ),
  getItem(
    "Nhật kí tìm kiếm địa chỉ",
    "16",
    <FieldTimeOutlined className="text-xl" />,
    "/log-search"
  ),
];

export default nav;
