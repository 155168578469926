import {
  LogoutOutlined,
  MailOutlined,
  MenuOutlined,
  RedoOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Badge,
  Breadcrumb,
  Button,
  Col,
  Dropdown,
  Image,
  Layout,
  Menu,
  Row,
  Space,
  theme,
  Typography,
} from "antd";

import React, { memo, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { LOCAL_STORAGE_ITEMS_NAME } from "../constants";
import useWindowDimensions from "../constants/until";
import nav from "../routes/nav";
import { logoutAction } from "../store/auth/authSlice";
import { mainLogo } from "../themes/images";
import { getLocalStorageItem } from "../utils";
// import "./styles.css";
const { Header, Content, Sider, Footer } = Layout;
const { Text, Link } = Typography;

const MainLayout = (props) => {
  const [displayMenu, setDisplayMenu] = useState(true);
  const [collapsed, setCollapsed] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { height, width } = useWindowDimensions();
  const userLogged = JSON.parse(
    getLocalStorageItem(LOCAL_STORAGE_ITEMS_NAME.AUTH_INFO)
  );

  const [selectedKey, setSelectedKey] = useState(
    nav.find((_item) => location.pathname.startsWith(_item.path)).key
  );

  const [menuLabelSelected,setMenuLabel]= useState('')

  useEffect(() => {
    const pathCurrent = location.pathname;
    let activeKey = "1";
    if (pathCurrent !== "/") {
      const splitPath = pathCurrent.split("/");
      activeKey = nav.find((_item) => {
        if (_item.path !== "/") {
          const splitPathItem = _item.path.split("/");
          return splitPath[1].startsWith(splitPathItem[1]);
        }
      }).key;
    }

    const label = nav.find((el) => el.key === activeKey);
    setSelectedKey(activeKey);
    setMenuLabel(label?.label??'');
  }, [location]);

  const navigateTo = (e) => {
    if (e?.item?.props?.path) {
      navigate(e?.item?.props?.path);
      // if (e.key) {
      //   setLocalStorageItem(LOCAL_STORAGE_ITEMS_NAME.SIDED_ACTIVE_ITEM, e?.key);
      // }
    }
  };

  const handleClickUser = (e) => {
    if (e?.key === "3") {
      dispatch(logoutAction());
      navigate("/login");
    }
  };

  const handleTongleMenu = () => {
    setDisplayMenu(!displayMenu);
  };

  const menu = (
    <Menu
      onClick={handleClickUser}
      items={[
        {
          label: "Đổi mật khẩu",
          key: "2",
          icon: <RedoOutlined />,
        },
        {
          type: "divider",
        },
        {
          label: "Đăng xuất tài khoản",
          key: "3",
          icon: <LogoutOutlined />,
        },
      ]}
    />
  );

  return (
    <Layout className="w-screen h-screen">
      <Sider
        onMouseEnter={() => setCollapsed(false)}
        onMouseLeave={() => setCollapsed(true)}
        collapsible
        collapsed={collapsed}
        onBreakpoint={(broken) => {
          console.log(broken);
        }}
        onCollapse={(collapsed, type) => {
          console.log(collapsed, type);
        }}
        breakpoint="xs"
        collapsedWidth={+width < 500 ? "0" : "80"}
        trigger={null}
      >
        <Menu
          className="h-screen p-1"
          defaultSelectedKeys={["1"]}
          mode="inline"
          items={nav}
          onSelect={navigateTo}
          selectedKeys={[selectedKey]}
          theme="dark"
        />
      </Sider>
      <Layout className="surface-section">
        <Header
          style={{
            padding: 0,
            backgroundColor: "white",
          }}
        >
          <Row className="header-title">
            <Col onClick={() => setCollapsed(!collapsed)} md={0} span={12}>
              <MenuOutlined />
            </Col>
            <Col md={12} span={0}>
              <Image width={150} preview={false} src={mainLogo} />
            </Col>
            <Col md={12} span={0}>
              <div className="flex justify-content-end pr-5 ">
                <Text strong className="mr-2">
                  {userLogged?.userName ?? ""} - Management
                </Text>
                <Badge
                  status="processing"
                  className="z-2"
                  offset={[110, -10]}
                ></Badge>
                <Text className="mr-3">Đang hoạt động</Text>
                <Dropdown overlay={menu}>
                  <Badge className="" color="#f50" offset={[0, 20]}>
                    <Avatar
                      src={userLogged?.image ?? "/user.png"}
                      onClick={(e) => e.preventDefault()}
                      icon={<UserOutlined />}
                      size="large"
                    />
                  </Badge>
                </Dropdown>
              </div>
            </Col>
          </Row>
        </Header>
        <Content className=" overflow-y-auto">
          <Breadcrumb
            style={{
              margin: "5px 10px",
              color: "#1890FF",
              
            }}
          >
            Trang chủ / {`${menuLabelSelected}`}
          </Breadcrumb>
          <div className="bg-white min-h-screen w-full p-1 md:py-4 md:pl-4 ">
            {props.children}
          </div>
        </Content>
        {/* <Footer style={{ textAlign: "center" }}>
          OnExpress ©2023 Created by Techlogs JST, Co.
        </Footer> */}
      </Layout>
    </Layout>
  );
};

export default memo(MainLayout);
