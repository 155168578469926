import { createSlice } from "@reduxjs/toolkit";
import { changeStateValues } from "../../utils";
const initialState = {
  dataUser: [],
  users: {},
  isToggle: false,
  pagination: {},
  title: null,
};

const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    toggleDrawer: changeStateValues,
    editUser: changeStateValues,
    getDataUser: changeStateValues,

    submitUser: (state, action) => {
      const index = state.dataUser.findIndex((item) => {
        return item.id === action.payload.id;
      });
      if (index !== -1) {
        const newDataUser = state.dataUser.map((item, i) => {
          if (i === index) {
            return action.payload;
          }
          return { ...item };
        });
        return { ...state, dataUser: newDataUser };
      }
      const newUser = {
        ...action.payload,
        id: state.dataUser.length + 1,
        key: state.dataUser.length + 1,
        stt: state.dataUser.length + 1,
      };
      return { ...state, dataUser: [...state.dataUser, newUser] };
    },
  },
});

export const { toggleDrawer, editUser, getDataUser, submitUser } =
  userSlice.actions;
export default userSlice.reducer;
