import { message } from "antd";
import React from "react";
import {
    FAIL_MESSAGE,
    PROCESSING_MESSAGE,
    SUCCESS_MESSAGE,
} from "../constants";

export const onSuccessMessage = (mess = SUCCESS_MESSAGE) => {
    message.success(mess);
    setTimeout(() => {
        message.destroy();
    }, 3000);
};
export const onFailMessage = (mess = FAIL_MESSAGE) => {
    message.error(mess);
    setTimeout(() => {
        message.destroy();
    }, 3000);
};

export const onWarningMessage = (mess) => {
    message.warning(mess);
    setTimeout(() => {
        message.destroy();
    }, 3000);
};

export const onProcessingMessage = (mess = PROCESSING_MESSAGE) =>
    message.loading(mess);
