import { ORDER_URL } from "../endPoints";
import httpClient from "../httpClientPartner";
import httpClientPortal from "../httpClientPortal";
const params = {
  username: "onexpress",
  password: "FLuGuaYP8fERGaaU",
  partner_id: "28823",
};

export async function getCalcFee(payload) {
  try {
    return httpClient.post(ORDER_URL.calcFee, payload, { headers: params });
  } catch (err) {
    return Promise.reject(err);
  }
}

export async function createOrder(payload) {
  try {
    return httpClient.post(ORDER_URL.create, payload, { headers: params });
  } catch (err) {
    return Promise.reject(err);
  }
}

export async function destroyOder(payload) {
  try {
    return httpClientPortal.post(ORDER_URL.destroy, payload, {
      headers: params,
      params: payload,
    });
  } catch (err) {
    return Promise.reject(err);
  }
}

export async function createOrderLocal(payload) {
  try {
    return httpClientPortal.post(ORDER_URL.createLocal, payload);
  } catch (err) {
    return Promise.reject(err);
  }
}

export async function createOrderExcel(payload, config) {
  try {
    return httpClient.post(ORDER_URL.createOder, payload, config);
  } catch (err) {
    return Promise.reject(err);
  }
}

export async function getOrderLocal(payload) {
  try {
    return httpClientPortal.get(ORDER_URL.getOderLocal, { params: payload });
  } catch (err) {
    return Promise.reject(err);
  }
}

export async function checkAddressLocal(payload) {
  try {
    return httpClientPortal.get(ORDER_URL.checkAddress, payload);
  } catch (err) {
    return Promise.reject(err);
  }
}
export async function getCargocontentService() {
  try {
    return httpClientPortal.get(ORDER_URL.getCargocontent);
  } catch (err) {
    return Promise.reject(err);
  }
}
