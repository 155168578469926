import { createSlice } from "@reduxjs/toolkit";
import {
    getAllShippingUnitThunk,
    updateShippingUnitDefaultThunk,
} from "./shippingUnitThunk";
import { onSuccessMessage, onFailMessage } from "../../components/message";

const initialState = {
    loading: false,
    allSihppingUnit: [],
};

const addressBookSlice = createSlice({
    name: "shippingUnit",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getAllShippingUnitThunk.fulfilled, (state, action) => {
            const { payload } = action;
            state.allSihppingUnit = payload?.data?.data;
        });
        // builder.addCase(
        //   updateShippingUnitDefaultThunk.fulfilled,
        //   (state, action) => {
        //     onSuccessMessage("Cập nhật đơn vị mặc định thành công");
        //   }
        // );
        // builder.addCase(
        //   updateShippingUnitDefaultThunk.rejected,
        //   (state, action) => {
        //     onFailMessage("Cập nhật đơn vị mặc định thất bại");
        //   }
        // );
    },
});

export const { addressBookAction } = addressBookSlice.actions;
export default addressBookSlice.reducer;
