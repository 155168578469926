export const AUTH_URL = {
  login: "authentication/loginAdmin",
};

export const ADDRESS_URL = {
  getAllCity: "/Common/City",
  getDistrict: "/Common/District",
  getWards: "/Common/Ward",
};

export const ORDER_URL = {
  // calcFee: "v1/calc-feeService",
  // create: "v1/bill/create-by-location",
  calcFee: "/v1/bill/calc-fee",
  create: "v1/bill/create-by-location",
  destroy: "v1/bill/destroy",
  createLocal: "OrderOnExpress/create",
  createOder: `https://apiws.ntx.com.vn/v1/bill/create-by-location/`,
  getOderLocal: `OrderOnExpress/SearchAdmin`,
  checkAddress: `Address/address`,
  getCargocontent: `cargocontent`,
};

export const DEPARTMENT_URL = {
  search: "/Estate/Search",
  create: "/Estate/Create",
  update: "/Estate/Update",
  delete: "v1/bill/destroy",
  status: "common/status",
};

export const REGISTER_URL = {
  generateOTP: "/OTP/GenerateOTP",
  checkOTP: "/OTP/Check",
  register: "/Authentication/Register",
};

export const BANK_URL = {
  getAllBank: "Bank/all",
  create: "/BankAccount/create",
  update: "/BankAccount/update",
  delete: "/BankAccount/delete/",
  search: "/BankAccount/search",
};

export const ADDRESS_BOOK_URL = {
  getAlladdressBook: "/AddressBook/all",
  create: "/AddressBook/create",
  update: "/AddressBook/update",
  delete: "/AddressBook/delete/",
  search: "/AddressBook/SearchV2",
};

export const STORE_MANAGER = {
  getAllPermission: "/Permission/getAll",
  createPermission: "/Permission/create",
  updatePermission: "/Permission/update",
  deletePermission: "Permission/delete",
  getByNamePermission: "/Permission/getByName",
};

export const STORE_MANAGER_GROUP = {
  getAllPermission: "/PermissionGroup/getAll",
  createPermission: "/PermissionGroup/create",
  updatePermission: "/PermissionGroup/update",
  deletePermission: "/PermissionGroup/delete/",
};

export const STORE_GROUP_USER = {
  getAllGroupUser: "/GroupUser/getAll",
  createGroupUser: "/GroupUser/create",
  updateGroupUser: "/GroupUser/update",
  deleteGroupUser: "/GroupUser/delete",
};

export const SHIPPING_UNIT = {
  getAllShippingUnit: "/delivery/getById/",
  updateShippingUnitDefault: "/Delivery/update",
};

export const WEB_HOOK_USER = {
  getWebHook: "webhook/getById",
  createWebHook: "/webhook/create",
  runWebhookForPartner: "/OrderOnExpress/PushOrderToPartner",
  getWebhookHistory: "/WebHookLog/GetWebhookLog",
};

export const PARTNER_CONNECTION = {
  getAllCustomerDelivery: "/CustomerDelivery/GetAll",
  searchCustomerDelivery: "CustomerDelivery/Search",
  getAllCustomerDeliveryUser: "/CustomerDelivery/GetAllUser",
  getAllDelivery: "/delivery/getall",
  create: "/CustomerDelivery/create",
  update: "/CustomerDelivery/update",
  delete: "/CustomerDelivery/delete",
};

export const SHOP_PARTNER = {
  search: "User/searchByAdmin",
  create: "User/createByAdmin",
  update: "User/updateByAdmin",
  delete: "User/deleteByAdmin",
  getConfig: "ApiSetting/GetById",
  updateConfig: "ApiSetting/Update",
  updatePayment: "ApiSetting/UpdatePayment",
  updateService: "/ApiSetting/UpdateService",
  getAllCondition: "/Common/Condition",
  getConditionByUserId: "/Common/Condition",
  updateCondition: "/Common/Condition",
};

export const SERVICE = {
  search: "Service",
  create: "Service/create",
  update: "Service/update",
  delete: "Service/delete/",
};

export const PAYMENTS = {
  getPayments: "PaymentMethod",
  createPayment: "PaymentMethod/create",
  updatePayment: "PaymentMethod/update",
  deletePayment: "PaymentMethod/delete",
};
export const TRACKING = {
  getTrackingByOrderId: "Tracking/GetByOrderId",
  creatTracking: "Tracking/create",
  updatTracking: "Tracking/update",
  deletTracking: "Tracking/delete/",
};

export const LOG_SEARCH = {
  getLogSearch: "/Common/LogSearch",
};
