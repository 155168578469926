import { AUTH_URL } from "../endPoints";
import httpClient from "../httpClientPortal";

export async function login(payload) {
  try {
    return httpClient.post(AUTH_URL.login, payload);
  } catch (err) {
    return Promise.reject(err);
  }
}
