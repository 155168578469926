import React from "react";
const Sevice = React.lazy(() => import("../pages/service"));
const StoreManager = React.lazy(() => import("../pages/storeManager"));
const UserAddress = React.lazy(() => import("../pages/user/address"));
const DashboardScreen = React.lazy(() => import("../pages/dashboard"));
const OrderScreen = React.lazy(() => import("../pages/order"));
const UserBank = React.lazy(() => import("../pages/user/userBank"));
const ShippingUnit = React.lazy(() => import("../pages/shippingUnit"));
const WebHook = React.lazy(() => import("../pages/webHook"));
const WebHookHub = React.lazy(() => import("../pages/webhookHub"));
const LogHistory = React.lazy(() => import("../pages/logHistory"));
const LogSearch = React.lazy(() => import("../pages/LogSearch"));

const PartnerConnection = React.lazy(() =>
  import("../pages/PartnerConnection")
);
const Payments = React.lazy(() => import("../pages/Payments"));
const ShopPartner = React.lazy(() => import("../pages/ShopPartner"));
const externalOder = React.lazy(() => import("../pages/externalOrder"));
const authRoutes = [
  {
    path: "/",
    component: DashboardScreen,
  },
  {
    path: "/order",
    component: OrderScreen,
  },
  {
    path: "/user/bank",
    component: UserBank,
  },
  {
    path: "/user/address",
    component: UserAddress,
  },
  {
    path: "/store-manager",
    component: StoreManager,
  },
  {
    path: "/user/ShippingUnit",
    component: ShippingUnit,
  },
  {
    path: "/user/webhook",
    component: WebHook,
  },
  {
    path: "/partner-connection",
    component: PartnerConnection,
  },
  {
    path: "/Payments",
    component: Payments,
  },
  {
    path: "/function/run-webhook",
    component: WebHookHub,
  },
  {
    path: "/logs-history",
    component: LogHistory,
  },
  {
    path: "/shop-partner",
    component: ShopPartner,
  },
  {
    path: "/service",
    component: Sevice,
  },
  {
    path: "/external-order",
    component: externalOder,
  },
  {
    path: "/log-search",
    component: LogSearch,
  },
];

export default authRoutes;
