import axios from "axios";
import { REQUEST_METHOD_MAPPING } from "../constants";
import { findHeaderConfig } from "../constants/requestPartnerConfig";

const sendGetWithQueryString = (endpoint, params, config) => {
  return axios.get(endpoint, { ...config, params: params });
};
const sendGetWithUrlParams = (endpoint, params, config) => {
  const urlParamsString = Object.values(params).join("/");
  const newUrl = endpoint + "/" + urlParamsString;
  return axios.get(newUrl, config);
};
const sendPostWithData = (endpoint, params, config) => {
  return axios.post(endpoint, params, config);
};

const sendPostWithFormData = (endpoint, params, config) => {
  const formData = new FormData();
  Object.keys(params).forEach((key) => {
    formData.append(key, params[key]);
  });
  return axios.post(endpoint, formData, config);
};

const sendPutWithData = (endpoint, data, config) => {
  return axios.put(endpoint, data, config);
};

const sendPutWithQueryString = (endpoint, data, config) => {
  return axios.put(endpoint, null, { ...config, params: data });
};

const sendDeleteWithUrlParams = (endpoint, params, config) => {
  const urlParamsString = Object.values(params).join("/");
  const newUrl = endpoint + "/" + urlParamsString;
  return axios.delete(newUrl, config);
};

const sendDeleteWithQueryString = (endpoint, params, config) => {
  return axios.delete(endpoint, { ...config, params });
};

const mappingRequestWithOptions = [
  { method: "get", requestType: "query", requestFunc: sendGetWithQueryString },
  { method: "get", requestType: "url", requestFunc: sendGetWithUrlParams },
  { method: "post", requestType: "body", requestFunc: sendPostWithData },
  {
    method: "post",
    requestType: "form_data",
    requestFunc: sendPostWithFormData,
  },
  { method: "put", requestType: "url", requestFunc: sendPutWithQueryString },
  { method: "put", requestType: "body", requestFunc: sendPutWithData },
  {
    method: "delete",
    requestType: "url",
    requestFunc: sendDeleteWithUrlParams,
  },
  {
    method: "delete",
    requestType: "url",
    requestFunc: sendDeleteWithQueryString,
  },
];

export const mappingRequest = (options, params, config) => {
  const { Endpoint, Method, RequestType,PartnerId } = options;
  const mappingMethod = REQUEST_METHOD_MAPPING[Method];
  const findMapRequest = mappingRequestWithOptions.find((el) => {
    return el.method === mappingMethod && el.requestType === RequestType;
  });

  const configHeader = findHeaderConfig(PartnerId);

  if (!findMapRequest) return;
  return findMapRequest.requestFunc(Endpoint, params, { ...configHeader,...config });
};
