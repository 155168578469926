import { createSlice } from "@reduxjs/toolkit";
import { getCityThunk, getDistrictThunk, getWardsThunk } from "./addressThunk";

const initialState = {
  city: [],
  city2: [],
  district: [],
  district2: [],
  districtTo: [],
  Wards: [],
  Wards2: [],
  WardsTo: [],
  loading: false,
};

const addressSlice = createSlice({
  name: "address",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getCityThunk.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(getCityThunk.fulfilled, (state, action) => {
      const { payload } = action;
      const data =
        payload?.data?.data?.map((item) => {
          return { ...item, value: item.area_code, label: item.name };
        }) ?? [];
      state.loading = false;
      state.city = data;
      state.city2 =
        payload?.data?.data?.map((item) => {
          return { ...item, value: item.Id, label: item.name };
        }) ?? [];
    });

    builder.addCase(getDistrictThunk.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(getDistrictThunk.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(getDistrictThunk.fulfilled, (state, action) => {
      const { payload } = action;
      const data =
        payload?.response?.data?.data.map((item) => ({
          ...item,
          label: item.name,
          value: item.district_code,
        })) ?? [];
      state.loading = false;
      payload.type === "from"
        ? (state.district = data)
        : (state.districtTo = data);
      state.district2 =
        payload?.response?.data?.data.map((item) => ({
          ...item,
          label: item.name,
          value: item.Id,
        })) ?? [];
    });

    builder.addCase(getWardsThunk.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(getWardsThunk.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(getWardsThunk.fulfilled, (state, action) => {
      const { payload } = action;
      state.Wards2 =
        payload?.response?.data?.data.map((item) => ({
          ...item,
          label: item.name,
          value: item.Id,
        })) ?? [];
      const data =
        payload?.response?.data?.data.map((item) => ({
          ...item,
          label: item.name,
          value: item.ward_code,
        })) ?? [];
      state.loading = false;
      payload.type === "from" ? (state.Wards = data) : (state.WardsTo = data);
    });

    builder.addCase(getCityThunk.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export default addressSlice.reducer;
