import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  createService,
  deleteService,
  getAllService,
  updateService,
} from "../../services/service";

export const getAllServiceThunk = createAsyncThunk(
  "service/getAllService",
  async (params, thunkAPI) => {
    try {
      const response = await getAllService(params);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const createServiceThunk = createAsyncThunk(
  "service/createService",
  async (params, thunkAPI) => {
    try {
      const response = await createService(params);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const updateServiceThunk = createAsyncThunk(
  "service/updateService",
  async (params, thunkAPI) => {
    try {
      const response = await updateService(params);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const deleteServiceThunk = createAsyncThunk(
  "service/deleteService",
  async (params, thunkAPI) => {
    try {
      const response = await deleteService(params);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
