import { createSlice } from "@reduxjs/toolkit";
import {
  getCalcFeeThunk,
  getCargocontent,
  getOrderLocalThunk,
} from "./orderThunk";
const initialState = {
  order: [],
  payment: {},
  payments: [],
  cargoContent: [],
  serviceId: 1,
  searchDrawer: false,
  pagination: {},
  loading: false,
};

const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    handleChangeServiceNameAction: (state, action) => {
      const payment = (state.payments ?? []).filter(
        (item) => item.service_id === action.payload
      );
      return { ...state, serviceId: action.payload, payment: payment[0] };
    },
    clearPayment: (state, action) => {
      return { ...state, payment: [] };
    },
    toggleSearchDrawer: (state, action) => {
      state.searchDrawer = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCalcFeeThunk.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getCalcFeeThunk.fulfilled, (state, action) => {
      const { payload } = action;
      const data = payload?.data?.data?.filter(
        (item) => item.service_id === state.serviceId
      );
      const datas = payload?.data?.data;
      state.loading = false;
      state.payment = data?.[0] ?? {};
      state.payments = datas;
    });
    builder.addCase(getCalcFeeThunk.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(getOrderLocalThunk.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getOrderLocalThunk.fulfilled, (state, action) => {
      const { payload } = action;
      const data = payload?.data?.data || [];
      const pagination = payload?.data?.pagination ?? {};
      state.loading = false;
      state.order = data;
      state.pagination = pagination;
    });
    builder.addCase(getOrderLocalThunk.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getCargocontent.fulfilled, (state, action) => {
      if (action.payload.data.status === 200) {
        state.cargoContent = action?.payload?.data?.data?.map((item) => ({
          value: item.Id,
          label: item.Name,
        }));
      }
    });
  },
});

export const {
  handleChangeServiceNameAction,
  clearPayment,
  toggleSearchDrawer,
} = userSlice.actions;
export default userSlice.reducer;
