import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  createDepartment,
  deleteDepartment,
  getDepartment,
  getStatusService,
  updateDepartment,
} from "../../services/department";

export const getDepartmentThunk = createAsyncThunk(
  "department/get",
  async (params, thunkAPI) => {
    try {
      const response = await getDepartment(params);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const createDepartmentThunk = createAsyncThunk(
  "department/create",
  async (payload, thunkAPI) => {
    try {
      const response = await createDepartment(payload);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const updateDepartmentThunk = createAsyncThunk(
  "department/update",
  async (payload, thunkAPI) => {
    try {
      const response = await updateDepartment(payload);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const deleteDepartmentThunk = createAsyncThunk(
  "department/delete",
  async (params, thunkAPI) => {
    try {
      const response = await deleteDepartment(params);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const getStatusThunk = createAsyncThunk(
  "common/status",
  async (params, thunkAPI) => {
    try {
      const response = await getStatusService(params);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
