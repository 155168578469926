import { PAYMENTS } from "../endPoints";
import httpClient from "../httpClientPortal";

export async function getPaymentsSevice(params) {
  try {
    return httpClient.get(PAYMENTS.getPayments, { params });
  } catch (err) {
    return Promise.reject(err);
  }
}

export async function createPaymentsSevice(payload) {
  try {
    return httpClient.post(PAYMENTS.createPayment,  payload );
  } catch (err) {
    return Promise.reject(err);
  }
}

export async function updatePaymentsSevice(payload) {
  try {
    return httpClient.put(PAYMENTS.updatePayment, payload );
  } catch (err) {
    return Promise.reject(err);
  }
}

export async function deletePaymentsSevice(Id) {
  try {
    return httpClient.delete(`${PAYMENTS.deletePayment}/${Id}`);
  } catch (err) {
    return Promise.reject(err);
  }
}
