import { PARTNER_CONNECTION } from "../endPoints";
import httpClient from "../httpClientPortal";

export async function getAllCustomerDeliveryService(payload) {
  try {
    return httpClient.get(PARTNER_CONNECTION.getAllCustomerDelivery);
  } catch (err) {
    return Promise.reject(err);
  }
}

export async function searchCustomerDeliveryService(params) {
  try {
    return httpClient.get(PARTNER_CONNECTION.searchCustomerDelivery, {
      params,
    });
  } catch (err) {
    return Promise.reject(err);
  }
}

export async function getAllCustomerDeliveryUserService(payload) {
  try {
    return httpClient.get(PARTNER_CONNECTION.getAllCustomerDeliveryUser);
  } catch (err) {
    return Promise.reject(err);
  }
}
export async function getAllDeliveryService(payload) {
  try {
    return httpClient.get(PARTNER_CONNECTION.getAllDelivery);
  } catch (err) {
    return Promise.reject(err);
  }
}

export async function createCustomerDeliveryService(payload) {
  try {
    return httpClient.post(PARTNER_CONNECTION.create, payload);
  } catch (err) {
    return Promise.reject(err);
  }
}

export async function updateCustomerDeliveryService(payload) {
  try {
    return httpClient.put(PARTNER_CONNECTION.update, payload);
  } catch (err) {
    return Promise.reject(err);
  }
}

export async function deleteCustomerDeliveryService(Id) {
  try {
    // return httpClient.delete(`${PARTNER_CONNECTION.delete}?id=${Id}`);
    return httpClient.delete(`${PARTNER_CONNECTION.delete}`, {
      params: { id: Id },
    });
  } catch (err) {
    return Promise.reject(err);
  }
}
