import { createSlice } from "@reduxjs/toolkit";
import {
  getAllGroupUser,
  getAllPermission,
  getAllPermissionGroup,
  getByNamePermission,
} from "./storeManagerThunk";

const initialState = {
  dataPermission: [],
  dataGroupUser: [],
  dataPermissionGroup: [],
  isOpen: false,
  isUpdate: false,
  defaultValue: {},
};

const storeManager = createSlice({
  name: "storeManager",
  initialState,
  reducers: {
    openModalManagerAction: (state, action) => {
      state.defaultValue = action.payload?.defaultValue ?? {};
      state.isOpen = true;
      state.isUpdate = action.payload?.isUpdate ?? false;
    },
    closeModalStoreManagerAction: (state, action) => {
      state.defaultValue = {};
      state.isOpen = false;
      state.isUpdate = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllPermission.fulfilled, (state, action) => {
      state.dataPermission = action?.payload?.data?.data;
    });
    builder.addCase(getAllPermission.pending, (state, action) => {
      state.dataPermission = [];
    });
    builder.addCase(getByNamePermission.pending, (state, action) => {
      state.dataPermission = [];
    });
    builder.addCase(getByNamePermission.fulfilled, (state, action) => {
      state.dataGroupUser = action?.payload?.data?.data;
    });
    builder.addCase(getAllPermissionGroup.fulfilled, (state, action) => {
      state.dataPermissionGroup = action?.payload?.data?.data;
    });
    builder.addCase(getAllGroupUser.pending, (state, action) => {
      state.dataGroupUser = [];
    });
    builder.addCase(getAllGroupUser.fulfilled, (state, action) => {
      state.dataGroupUser = action?.payload?.data?.data;
    });
  },
});

export const { openModalManagerAction, closeModalStoreManagerAction } =
  storeManager.actions;
export default storeManager.reducer;
