import { createSlice } from "@reduxjs/toolkit";
import {
  searchShopPartner,
  getAllConditionForSwichOrderThunk,
  getConditionForSwichOrderByUserThunk,
} from "./ShopPartnerThunk";

const initialState = {
  data: [],
  pagination: {},
  loading: false,
  allSihppingUnit: [],
  isOpen: false,
  isUpdate: false,
  defaultValue: {},
  defaultCopy: {},
  isOpenFormCopy: false,
  isShowConfigModal: false,
  isShowConfigSwitchOrderModal: false,
  currentItem: {},
  allConditionForSwithOrder: [],
  conditionForSwitchOrderByUser: [],
};

const ShopPartNerSlice = createSlice({
  name: "shippingUnit",
  initialState,
  reducers: {
    openModalShopPartner: (state, action) => {
      state.defaultValue = action.payload?.defaultValue ?? {};
      state.isOpen = true;
      state.isUpdate = action.payload?.isUpdate ?? false;
    },
    closeModalShopPartner: (state, action) => {
      state.defaultValue = {};
      state.isOpen = false;
    },
    openModalFormCopyShopPartner: (state, action) => {
      state.defaultCopy = action.payload?.defaultCopy ?? {};
      state.isOpenFormCopy = true;
    },
    closeModalFormCopyShopPartner: (state, action) => {
      state.defaultCopy = {};
      state.isOpenFormCopy = false;
    },
    openConfigModal: (state, action) => {
      state.isShowConfigModal = !state.isShowConfigModal;
      state.currentItem = action.payload;
    },
    closeConfigModal: (state, action) => {
      state.isShowConfigModal = false;
      state.currentItem = {};
    },
    openConfigSwitchOrderModal: (state, action) => {
      state.isShowConfigSwitchOrderModal = !state.isShowConfigSwitchOrderModal;
      state.currentItem = action.payload;
    },
    closeConfigSwitchOrderModal: (state, action) => {
      state.isShowConfigSwitchOrderModal = false;
      state.currentItem = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(searchShopPartner.fulfilled, (state, action) => {
      state.data = action.payload.data.data;
      state.pagination = action.payload.data.pagination;
      state.loading = false;
    });
    builder.addCase(searchShopPartner.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(searchShopPartner.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(
      getAllConditionForSwichOrderThunk.fulfilled,
      (state, action) => {
        state.allConditionForSwithOrder = action.payload.data.data;
      }
    );
    builder.addCase(
      getAllConditionForSwichOrderThunk.rejected,
      (state, action) => {
        state.allConditionForSwithOrder = [];
      }
    );
    builder.addCase(
      getConditionForSwichOrderByUserThunk.fulfilled,
      (state, action) => {
        state.conditionForSwitchOrderByUser = action.payload.data.data;
      }
    );
    builder.addCase(
      getConditionForSwichOrderByUserThunk.rejected,
      (state, action) => {
        state.conditionForSwitchOrderByUser = [];
      }
    );
  },
});

export const {
  openModalShopPartner,
  closeModalShopPartner,
  openModalFormCopyShopPartner,
  closeModalFormCopyShopPartner,
  openConfigModal,
  closeConfigModal,
  openConfigSwitchOrderModal,
  closeConfigSwitchOrderModal,
} = ShopPartNerSlice.actions;
export default ShopPartNerSlice.reducer;
