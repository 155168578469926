import { SHIPPING_UNIT } from "../endPoints";
import httpClient from "../httpClientPortal";

export async function getAllShippingUnit(payload) {
  try {
    return httpClient.get(SHIPPING_UNIT.getAllShippingUnit + payload);
  } catch (err) {
    return Promise.reject(err);
  }
}
export async function updateShippingUnitDefault(params) {
  try {
    return httpClient.post(SHIPPING_UNIT.updateShippingUnitDefault, params);
  } catch (err) {
    return Promise.reject(err);
  }
}
