import { BANK_URL } from "../endPoints";
import httpClientPortal from "../httpClientPortal";

export async function getAllBankName(params) {
  try {
    return httpClientPortal.get(BANK_URL.getAllBank, params);
  } catch (err) {
    return Promise.reject(err);
  }
}
export async function create(params) {
  try {
    return httpClientPortal.post(BANK_URL.create, params);
  } catch (err) {
    return Promise.reject(err);
  }
}
export async function update(params) {
  try {
    return httpClientPortal.put(BANK_URL.update, params);
  } catch (err) {
    return Promise.reject(err);
  }
}
export async function deleteBank(Id) {
  try {
    return httpClientPortal.delete(BANK_URL.delete + Id);
  } catch (err) {
    return Promise.reject(err);
  }
}
export async function search(params) {
  try {
    return httpClientPortal.get(BANK_URL.search, { params });
  } catch (err) {
    return Promise.reject(err);
  }
}
