import { SERVICE } from "../endPoints";
import httpClientPortal from "../httpClientPortal";

export async function getAllService(params) {
  try {
    return httpClientPortal.get(SERVICE.search, { params });
  } catch (err) {
    return Promise.reject(err);
  }
}
export async function createService(params) {
  try {
    return httpClientPortal.post(SERVICE.create, params);
  } catch (err) {
    return Promise.reject(err);
  }
}
export async function updateService(params) {
  try {
    return httpClientPortal.put(SERVICE.update, params);
  } catch (err) {
    return Promise.reject(err);
  }
}
export async function deleteService(params) {
  try {
    return httpClientPortal.delete(SERVICE.delete + params);
  } catch (err) {
    return Promise.reject(err);
  }
}
