import { createAsyncThunk } from "@reduxjs/toolkit";
import { login } from "../../services/auth";

export const loginThunk = createAsyncThunk(
  "auth/login",
  async (params, thunkAPI) => {
    try {
      const response = await login(params);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
