import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  creatTracking,
  deletTracking,
  getTrackingByOrderId,
  updatTracking,
} from "../../services/tracking";

export const getTrackingByIdThunk = createAsyncThunk(
  "tracking/getTrackingById",
  async (params, thunkAPI) => {
    try {
      const response = await getTrackingByOrderId(params);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const creatTrackingThunk = createAsyncThunk(
  "tracking/creatTracking",
  async (params, thunkAPI) => {
    try {
      const response = await creatTracking(params);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const updatTrackingThunk = createAsyncThunk(
  "tracking/updatTracking",
  async (params, thunkAPI) => {
    try {
      const response = await updatTracking(params);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const deletTrackingThunk = createAsyncThunk(
  "tracking/deletTracking",
  async (params, thunkAPI) => {
    try {
      const response = await deletTracking(params);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
