import { ADDRESS_URL } from "../endPoints";
import httpClient from "../httpClientPortal";
const params = {
  username: "onexpress",
  password: "2UGvjtMr9KX5cATK",
  partner_id: "424",
};

export async function getAllCity() {
  try {
    return httpClient.get(ADDRESS_URL.getAllCity, { headers: { ...params } });
  } catch (err) {
    return Promise.reject(err);
  }
}

export async function getDistrictByCityId(area_code) {
  try {
    return httpClient.get(ADDRESS_URL.getDistrict, {
      params: { area_code },
      headers: { ...params },
    });
  } catch (err) {
    return Promise.reject(err);
  }
}

export async function getWards(district_code) {
  try {
    return httpClient.get(ADDRESS_URL.getWards, {
      params: { district_code },
      headers: { ...params },
    });
  } catch (err) {
    return Promise.reject(err);
  }
}
