import { SHOP_PARTNER } from "../endPoints";
import httpClient from "../httpClientPortal";

export async function searchShopPartnerService(params) {
    try {
        return httpClient.get(SHOP_PARTNER.search, { params });
    } catch (err) {
        return Promise.reject(err);
    }
}

export async function createShopPartnerService(payload) {
    try {
        return httpClient.post(SHOP_PARTNER.create, payload);
    } catch (err) {
        return Promise.reject(err);
    }
}

export async function updateShopPartnerService(payload) {
    try {
        return httpClient.put(SHOP_PARTNER.update, payload);
    } catch (err) {
        return Promise.reject(err);
    }
}

export async function deleteShopPartnerService(Id) {
    try {
        return httpClient.delete(SHOP_PARTNER.delete, { params: { Id: Id } });
    } catch (err) {
        return Promise.reject(err);
    }
}

export const getConfigShopPartner = (params) => {
    return httpClient.get(SHOP_PARTNER.getConfig, { params });
};

export const updateConfigShopPartner = (payload) => {
    return httpClient.put(SHOP_PARTNER.updateConfig, payload);
};

export const updateConfigPaymentMethod = (payload) => {
    return httpClient.put(SHOP_PARTNER.updatePayment, payload);
};

export const updateConfigService = (payload) => {
    return httpClient.put(SHOP_PARTNER.updateService, payload);
};

export const getAllConditionSwithOrder = () => {
    return httpClient.get(SHOP_PARTNER.getAllCondition);
};

export const getConditionSwithOrderByUserId = (params) => {
    return httpClient.get(SHOP_PARTNER.getConditionByUserId, { params });
};

export const updateConditionSwithOrder = (payload, userId) => {
    return httpClient.post(
        `${SHOP_PARTNER.updateCondition}/${userId}`,
        payload
    );
};
