import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getAlladdressBookName,
  create,
  update,
  deleteaddressBook,
  search,
} from "../../services/addressBook";

export const getAllAddressBookThunk = createAsyncThunk(
  "addressBook/getAlladdressBook",
  async (params, thunkAPI) => {
    try {
      const response = await getAlladdressBookName(params);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const createThunk = createAsyncThunk(
  "addressBook/create",
  async (params, thunkAPI) => {
    try {
      const response = await create(params);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const updateThunk = createAsyncThunk(
  "addressBook/update",
  async (params, thunkAPI) => {
    try {
      const response = await update(params);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const deleteAddressBookThunk = createAsyncThunk(
  "addressBook/deleteaddressBook",
  async (params, thunkAPI) => {
    try {
      const response = await deleteaddressBook(params);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const searchThunk = createAsyncThunk(
  "addressBook/search",
  async (params, thunkAPI) => {
    try {
      const response = await search(params);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
