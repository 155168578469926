import { isNullOrEmpty } from "./utils";
const StringNumberWithoutComma = (string) => {
    if (isNullOrEmpty(string)) return null;
    if (typeof string !== "string") return string;
    const stringStartsWithMinus = string.startsWith("-");
    const normalizeString = string.replaceAll(/,|\-/g, "");
    const result = stringStartsWithMinus ? -normalizeString : normalizeString;
    return result;
};

const reverseString = (str) => {
    if (!str) return null;
    if (str === "") return "";
    // Step 1. Use the split() method to return a new array
    var splitString = str.split(""); // var splitString = "hello".split("");
    // ["h", "e", "l", "l", "o"]

    // Step 2. Use the reverse() method to reverse the new created array
    var reverseArray = splitString.reverse(); // var reverseArray = ["h", "e", "l", "l", "o"].reverse();
    // ["o", "l", "l", "e", "h"]

    // Step 3. Use the join() method to join all elements of the array into a string
    var joinArray = reverseArray.join(""); // var joinArray = ["o", "l", "l", "e", "h"].join("");
    // "olleh"

    //Step 4. Return the reversed string
    return joinArray; // "olleh"
};

function formatIntegerToReadableString(trimNumber) {
    if (isNullOrEmpty(trimNumber)) return null;
    const numberGroups = [];
    const isNagativeNumber = Number(trimNumber) < 0;
    const positiveNumber = isNagativeNumber ? trimNumber.slice(1) : trimNumber;
    const length = positiveNumber.length;
    for (let i = length; i > 0; i -= 3) {
        let startPoint = i - 3;
        if (startPoint < 0) {
            startPoint = 0;
        }
        const numberGroup = positiveNumber.slice(startPoint, i);
        numberGroups.unshift(numberGroup);
    }
    let formatedNumber = numberGroups.join(",");
    formatedNumber = isNagativeNumber ? "-" + formatedNumber : formatedNumber;
    return formatedNumber;
}

const formatDecimalToReadableString = (string) => {
    const [integerPart, fractionalPart] = string.split(".");
    const formatedIntegerPart = formatNumberToReadableString(integerPart);
    const reverseFractionalPart = reverseString(fractionalPart);
    const formatedReverseFractionalPart = formatNumberToReadableString(
        reverseFractionalPart
    );
    const formatedFractionalPart = reverseString(formatedReverseFractionalPart);
    const result = `${formatedIntegerPart ? formatedIntegerPart : 0}.${
        formatedFractionalPart ? formatedFractionalPart : ""
    }`;
    return result;
};

export const formatNumberToReadableString = (number) => {
    let result = null;
    if (isNullOrEmpty(number)) return null;
    const trimNumber = `${number}`.split(",").join("");
    if (trimNumber === "" || isNaN(trimNumber)) return null;

    // debugger here
    const realNumber = `${StringNumberWithoutComma(trimNumber)}`;

    if (trimNumber.indexOf(".") >= 0) {
        result = formatDecimalToReadableString(realNumber);
    } else {
        result = formatIntegerToReadableString(realNumber);
    }
    return result;
};
