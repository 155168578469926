import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getAllBankName,
  create,
  update,
  deleteBank,
  search,
} from "../../services/bank";

export const getAllBankThunk = createAsyncThunk(
  "bank/getAllBank",
  async (params, thunkAPI) => {
    try {
      const response = await getAllBankName(params);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const createThunk = createAsyncThunk(
  "bank/create",
  async (params, thunkAPI) => {
    try {
      const response = await create(params);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const updateThunk = createAsyncThunk(
  "bank/update",
  async (params, thunkAPI) => {
    try {
      const response = await update(params);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const deleteBankThunk = createAsyncThunk(
  "bank/deleteBank",
  async (params, thunkAPI) => {
    try {
      const response = await deleteBank(params);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const searchThunk = createAsyncThunk(
  "bank/search",
  async (params, thunkAPI) => {
    try {
      const response = await search(params);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
