import { createAsyncThunk } from "@reduxjs/toolkit";
import { createWebHookService, getWebHookService } from "../../services/webHook";

export const createWebHook = createAsyncThunk(
    "user/createWebHook",
    async (payload, thunkAPI) => {
      try {
        const response = await createWebHookService(payload);
        return response;
      } catch (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  );

export const getWebHook = createAsyncThunk(
    "user/getWebHook",
    async (userId, thunkAPI) => {
      try {
        const response = await getWebHookService(userId);
        return response;
      } catch (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  );