import { Select } from "antd";
import React from "react";

const { Option } = Select;

export const isNull = (data) => {
  return data === undefined || data === null;
};

export const isTrue = (data) => {
  return data === "true" || data === true;
};

export const isEmptyArray = (data) => {
  return Array.isArray(data) && data.length === 0;
};

export const isEmptyString = (data) => {
  return typeof data === "string" && data.length === 0;
};

export const isEmptyObject = (data) => {
  return typeof data === "object" && Object.keys(data).length === 0;
};

export const isNullOrEmpty = (data) => {
  let result = false;
  const dataIsNull = isNull(data);
  if (dataIsNull) {
    result = true;
  } else {
    const dataIsEmptyArray = isEmptyArray(data);
    const dataIsEmptyObject = isEmptyObject(data);
    const dataIsEmptyString = isEmptyString(data);
    if (dataIsEmptyArray || dataIsEmptyObject || dataIsEmptyString) {
      result = true;
    }
  }
  return result;
};

export function normalizeToOptions(items, labelField) {
  if (isNullOrEmpty(items)) {
    return [];
  }
  const result = items.map((item) => normalizeToOption(item, labelField));
  return result;
}

export function normalizeToOption(item, labelField) {
  if (isNullOrEmpty(item)) {
    return undefined;
  }
  let label = null;
  if (typeof labelField === "function") {
    label = labelField(item);
  } else {
    label = item[labelField];
  }
  const value = { ...item, value: item.Id, label };
  return value;
}

export const getOptionsSelect = (options) => {
  if (!options) return [];
  return options.map((el, i) => (
    <Option value={el.value} key={i}>
      {el.label}
    </Option>
  ));
};

export const SETTING_NAME = {
  TRACKING: "Tracking",
  CANCEL_ODER: "CancelOrder",
};

export const getAptSettingByName = (setting, Name) =>
  setting.find((setting) => setting.Name === Name);
