import axios from "axios";
import { COOKIE_ITEMS_NAME } from "../constants";
import { getCookie, deleteAllCookies, removeAllLocalStorage } from "../utils";

let httpClient = axios.create({
    baseURL: `${process.env.REACT_APP_PORTAL_HOST_URL}/api`,
});

const accessToken = getCookie(COOKIE_ITEMS_NAME.ACCESS_TOKEN);

if (accessToken) {
    httpClient.defaults.headers.common["apikey"] = accessToken;
}

httpClient.interceptors.request.use((config) => {
    return config;
});

httpClient.interceptors.response.use(
    function (res) {
        return res;
    },

    function (error) {
        if (error?.response?.status === 401) {
            deleteAllCookies();
            removeAllLocalStorage();
            window.location.href = "/login";
        }
        return Promise.reject(error);
    }
);

export default httpClient;
