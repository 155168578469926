import { createSlice } from "@reduxjs/toolkit";
import { getWebHook } from "./webHookThunk";
const initialState = {
  dataWebHook: [],
};

const webHookSlice = createSlice({
  name: "webHook",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getWebHook.fulfilled, (state, action) => {
      if (action?.payload?.data?.status === 200) {
        state.dataWebHook = action.payload.data.data;
      }
    });
  },
});

export const {} = webHookSlice.actions;
export default webHookSlice.reducer;
