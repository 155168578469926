import axios from "axios";

const apiConfig = {
  BASE_URL: process.env.REACT_APP_NTX_HOST_URL,
};

let httpClient = axios.create({
  baseURL: `${apiConfig.BASE_URL}`,
});

httpClient.interceptors.request.use((config) => {
  return config;
});

httpClient.interceptors.response.use(
  function (res) {
    return res;
  },

  function (error) {}
);

export default httpClient;
