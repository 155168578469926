import { createSlice } from "@reduxjs/toolkit";
import { getLogSearchThunk } from "./LogSearchThunk";
const initialState = {
  data: [],
  pagination: {},
  loading:false
};

const LogSearchSlice = createSlice({
  name: "LogSearch",
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(getLogSearchThunk.fulfilled, (state, action) => {
      state.data = action.payload.data.data;
      state.pagination = action?.payload?.data?.pagination??[];
      state.loading = false;
    });
    builder.addCase(getLogSearchThunk.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getLogSearchThunk.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export const {  } = LogSearchSlice.actions;
export default LogSearchSlice.reducer;
