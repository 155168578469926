import { WEB_HOOK_USER } from "../endPoints";
import httpClient from "../httpClientPortal";

export async function createWebHookService(payload) {
  try {
    return httpClient.post(WEB_HOOK_USER.createWebHook, payload);
  } catch (err) {
    return Promise.reject(err);
  }
}

export async function getWebHookService(userId) {
  try {
    return httpClient.get(`${WEB_HOOK_USER.getWebHook}/${userId}`);
  } catch (err) {
    return Promise.reject(err);
  }
}

export function runWebhookForPartner(payload) {
  return httpClient.post(`${WEB_HOOK_USER.runWebhookForPartner}`, payload);
}

export function getWebhookHistory(params) {
  return httpClient.get(`${WEB_HOOK_USER.getWebhookHistory}`, { params });
}
