export const LOCAL_STORAGE_ITEMS_NAME = {
    SIDED_ACTIVE_ITEM: "SIDED_ACTIVE_ITEM",
    AUTH_INFO: "AUTH_INFO",
};

export const COOKIE_ITEMS_NAME = {
    ACCESS_TOKEN: "ACCESS_TOKEN_ADMIN_MANAGEMENT",
};

export const REQUEST_METHOD_MAPPING = {
    GET: "get",
    PUT: "put",
    POST: "post",
    DELETE: "delete",
};

export const DEFAULT_PAGE_SIZE = 100;
export const PAGE = 1;

export const SUCCESS_MESSAGE = "Thực hiện thành công";
export const FAIL_MESSAGE = "Thất bại !";
export const PROCESSING_MESSAGE = "Đang thực hiện tiến trình...";

export const SHIPPING_PROVIDER_IDS = {
    NTX: 1,
    NTL: 2,
    VIETTEL_POST: 3,
    ONEXPRESS: 998,
    OTHER: 999,
};

export const PROVIDER_PARTNER_IDS = {
    NTX: 1,
    NTL: 2,
    VIETTEL_POST: 3,
    ONEXPRESS: 998,
    OTHER: 999,
};

export const AUTH_TYPES = {
    BASIC: "Auth-Basic",
    OAUTH2: "OAuth2",
};

export const AUTHENTICATION_TYPE_OF_PROVIDER = [
    { Name: "NTX", ProviderId: 1, Type: AUTH_TYPES.BASIC },
    { Name: "NTL", ProviderId: 2, Type: AUTH_TYPES.BASIC },
    { Name: "VIETTEL_POST", ProviderId: 3, Type: AUTH_TYPES.OAUTH2 },
];

export const INITIAL_DEFAULT_VALUES = {
    CARGO_CONTENT_ID: 8, // MAC DINH LOAI HANG KHAC
    CARGO_CONTENT_LABEL: "Khác", // MAC DINH LOAI HANG KHAC LABEL
};

export const ORDER_CONSTANS = {
    DRAF_ORDER_FIELD: "isDrafOrder",
    NEW_ORDER_STATUS_ID: 1,
    PRI_ORDER_STATUS_ID: 2,
    LPC_ORDER_STATUS_ID: 3,
    GBV_ORDER_STATUS_ID: 6,
};
