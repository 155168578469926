import moment from "moment";

export const formatTimeToDMYString = (time) => {
    if (!time) return "";
    return moment(time).format("DD/MM/YYYY");
};

export const formatTimeToDMYHSMString = (time) => {
    if (!time) return "";
    return moment(time).format("DD/MM/YYYY HH:mm:ss");
};

export const initTimeDMYHSMString = () => {
    return moment().format("DDMMYYHHSSmm");
};
