import { createSlice } from "@reduxjs/toolkit";
import { getAllCustomerDelivery, getAllCustomerDeliveryUser, getAllDelivery, searchCustomerDelivery } from "./partnerConnectionThunk";

const initialState = {
  data: [],
  pagination: {},
  dataUser: [],
  dataDelivery: [],
  defaultValue:{},
  defaultCopy:{},
  isOpen:false,
  isOpenFormCopy:false,
  isUpdate:false,
  loading:false
};

const partnerConnectionSlice = createSlice({
  name: "register",
  initialState,
  reducers: {
    openModalpartnerConnection: (state, action) => {
        state.defaultValue = action.payload?.defaultValue ?? {};
        state.isOpen = true;
        state.isUpdate = action.payload?.isUpdate ?? false;
      },
      closeModalStorepartnerConnection: (state, action) => {
        state.defaultValue = {};
        state.isOpen = false;
        state.isUpdate = false;
      },
      openModalFormCopy: (state, action) => {
        state.defaultCopy = action.payload?.defaultCopy ?? {};
        state.isOpenFormCopy = true;
      },
      closeModalFormCopy: (state, action) => {
        state.defaultCopy = {};
        state.isOpenFormCopy = false;
      },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllCustomerDelivery.fulfilled, (state, action) => {
      state.data= action.payload.data.data
      state.pagination= action.payload.data.pagination
      state.loading=false
    });
    builder.addCase(getAllCustomerDelivery.pending, (state, action) => {
      state.loading=true
    });
    builder.addCase(getAllCustomerDelivery.rejected, (state, action) => {
      state.loading=false
    });

    
    builder.addCase(searchCustomerDelivery.fulfilled, (state, action) => {
      state.data= action.payload.data.data
      state.pagination= action.payload.data.pagination
      state.loading=false
    });
    builder.addCase(searchCustomerDelivery.pending, (state, action) => {
      state.loading=true
    });
    builder.addCase(searchCustomerDelivery.rejected, (state, action) => {
      state.loading=false
    });


    builder.addCase(getAllCustomerDeliveryUser.fulfilled, (state, action) => {
      state.dataUser= action.payload.data.data
    });
    builder.addCase(getAllDelivery.fulfilled, (state, action) => {
      state.dataDelivery= action.payload.data.data
    });
  },
});

export const {openModalpartnerConnection,closeModalStorepartnerConnection,openModalFormCopy,closeModalFormCopy} = partnerConnectionSlice.actions;
export default partnerConnectionSlice.reducer;
