import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import MainLayout from "../components/mainLayout";
import { COOKIE_ITEMS_NAME } from "../constants";
import { getCookie } from "../utils";

const PrivateRoutes = () => {
  // const accessToken = true;
  const accessToken = getCookie(COOKIE_ITEMS_NAME.ACCESS_TOKEN);

  return accessToken ? (
    <MainLayout>
      <Outlet />
    </MainLayout>
  ) : (
    <Navigate to="/login" />
  );
};

export default PrivateRoutes;
