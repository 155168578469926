import Cookies from "js-cookie";
import { isNull, kebabCase } from "lodash";
import { useState, useEffect } from "react";
const _ = require("lodash");
export const formatNumber = (number) => {
  if (typeof number !== "number") return number;
  const newNumber = number.toLocaleString();
  return newNumber;
};

export function checkObjectNull(obj) {
  return +_.isEmpty(obj);
}

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}
export function addFromData(obj) {
  const newData = new FormData();
  Object.keys(obj).forEach((key) => {
    // console.log(newData);
    return newData.append(key, obj[key]);
  });
  return newData;
}
export function formatPhoneNumber(number) {
  // Kiểm tra đầu vào
  if (typeof number !== "string") {
    return "";
  }

  // Xóa tất cả các ký tự không phải số khỏi chuỗi
  number = number.replace(/\D/g, "");

  // Chia chuỗi thành 3 phần cách nhau bởi dấu cách
  return number.replace(/(\d{3})(\d{3})(\d{4})/, "$1 $2 $3");
}

export const SECRET_KEY =
  "ciLCJuYW1lIjoiY3Vvbmd0bSIsInBob25lbnVtYmVyIjoiMDM2Nzg3NjIwMiIs";
export function saveValue(key, value, meta) {
  Cookies.set(key, value, meta ? meta : { expires: 7 });
}
export function getSavedValue(key, defaultValue) {
  let value = Cookies.get(key);
  if (isNull(value)) {
    value = defaultValue;
  }
  return value;
}
export function removeKey(key) {
  Cookies.remove(key);
}
