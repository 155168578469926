import { createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import {
  creatTrackingThunk,
  deletTrackingThunk,
  getTrackingByIdThunk,
  updatTrackingThunk,
} from "./TrackingThunk";

const initialState = {
  loading: false,
  trackingData: [],
  IsUpdated: false,
  updateItems: {},
  message: { type: "success", message: "" },
};

const trackingSlice = createSlice({
  name: "tracking",
  initialState,
  reducers: {
    updateTracking: (state, action) => {
      state.IsUpdated = action?.payload?.IsUpdated;
      state.updateItems = action?.payload?.updateItems;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTrackingByIdThunk.fulfilled, (state, action) => {
      const { payload } = action;
      state.trackingData = payload?.data?.data;
      state.loading = false;
    });
    builder.addCase(getTrackingByIdThunk.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getTrackingByIdThunk.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(creatTrackingThunk.fulfilled, (state, action) => {
      message.success("Thêm mới thành công");
    });

    builder.addCase(creatTrackingThunk.rejected, (state, action) => {
      message.error("Thêm mới thất bại");
    });
    builder.addCase(updatTrackingThunk.fulfilled, (state, action) => {
      console.log(action);
      state.IsUpdated = false;
      state.updateItems = {};
      message.success("Cập  thất thành công");
    });

    builder.addCase(updatTrackingThunk.rejected, (state, action) => {
      message.error("Cập  thất bại");
    });
    builder.addCase(deletTrackingThunk.fulfilled, (state, action) => {
      console.log(action);
      state.IsUpdated = false;
      state.updateItems = {};
      message.success("Xóa thành công");
    });

    builder.addCase(deletTrackingThunk.rejected, (state, action) => {
      message.error("Xóa thất bại");
    });
  },
});

export const { trackingAction, updateTracking } = trackingSlice.actions;
export default trackingSlice.reducer;
