import { createSlice } from "@reduxjs/toolkit";
import {
  createServiceThunk,
  getAllServiceThunk,
  updateServiceThunk,
} from "./ServiceThunk";

const initialState = {
  loading: false,
  serviceData: [],
  IsUpdated: false,
  updateItems: {},
};

const serviceSlice = createSlice({
  name: "service",
  initialState,
  reducers: {
    updateService: (state, action) => {
      state.IsUpdated = action?.payload?.IsUpdated;
      state.updateItems = action?.payload?.updateItems;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllServiceThunk.fulfilled, (state, action) => {
      const { payload } = action;
      state.serviceData = payload?.data?.data;
      state.loading = false;
    });
    builder.addCase(getAllServiceThunk.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getAllServiceThunk.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(createServiceThunk.fulfilled, (state, action) => {});

    builder.addCase(createServiceThunk.rejected, (state, action) => {});
    builder.addCase(updateServiceThunk.fulfilled, (state, action) => {
      console.log(action);
      state.IsUpdated = false;
      state.updateItems = {};
    });

    builder.addCase(updateServiceThunk.rejected, (state, action) => {});
  },
});

export const { serviceAction, updateService } = serviceSlice.actions;
export default serviceSlice.reducer;
