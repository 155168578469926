import { createAsyncThunk } from "@reduxjs/toolkit";
import { LOCAL_STORAGE_ITEMS_NAME } from "../../constants";
import { addFromData } from "../../constants/until";
import {
    getAllShippingUnit,
    updateShippingUnitDefault,
} from "../../services/shippingUnit";
import { getLocalStorageItem, isNullOrEmpty } from "../../utils";

export const getAllShippingUnitThunk = createAsyncThunk(
    "shippingUnit/getAllshippingUnit",
    async (params, thunkAPI) => {
        try {
            const users = await JSON.parse(
                getLocalStorageItem(LOCAL_STORAGE_ITEMS_NAME.AUTH_INFO)
            );
            const response = await getAllShippingUnit(params || users.userId);
            return response;
        } catch (err) {
            return thunkAPI.rejectWithValue(err);
        }
    }
);

export const updateShippingUnitDefaultThunk = createAsyncThunk(
    "shippingUnit/updateShippingUnitDefault",
    async (params, thunkAPI) => {
        try {
            params.DeliveryCode =
                isNullOrEmpty(params.DeliveryCode) ||
                params.DeliveryCode === "null"
                    ? null
                    : params.DeliveryCode;
            const response = await updateShippingUnitDefault(params);
            return response;
        } catch (err) {
            console.log("fuck--------");
            return thunkAPI.rejectWithValue(err);
        }
    }
);
