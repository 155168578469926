import { createSlice } from "@reduxjs/toolkit";
import {
  getAllBankThunk,
  createThunk,
  updateThunk,
  deleteBankThunk,
  searchThunk,
} from "./bankThunk";

const initialState = {
  loading: false,
  bankList: [],
  allBankCard: [],
  mess: null,
  isLogged: false,
};

const bankSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    bankAction: (state, action) => {
      // removeAllLocalStorage();
      // deleteAllCookies();
    },
  },
  extraReducers: (builder) => {
    console.log("vao day chu2");
    builder.addCase(getAllBankThunk.fulfilled, (state, action) => {
      const { payload } = action;
      state.bankList = payload?.data?.data;
    });
    builder.addCase(searchThunk.fulfilled, (state, action) => {
      const { payload } = action;
      state.allBankCard = payload?.data?.data;
    });
    builder.addCase(deleteBankThunk.fulfilled, (state, action) => {
      state.loading = !state.loading;
    });
    builder.addCase(createThunk.fulfilled, (state, action) => {
      state.loading = !state.loading;
    });
    builder.addCase(updateThunk.fulfilled, (state, action) => {
      state.loading = !state.loading;
    });
    // builder.addCase(loginThunk.fulfilled, (state, action) => {
    //   console.log("vao day chu1");
    //   const { payload } = action;
    //   console.log(
    //     "🚀 ~ file: bankSlice.js ~ line 45 ~ builder.addCase ~ payload",
    //     payload
    //   );
    //   const data = payload?.data?.data ?? null;
    //   const token = data?.Token ?? null;
    //   if (token) {
    //     setCookie(COOKIE_ITEMS_NAME.ACCESS_TOKEN, token, 1);
    //     state.isLogged = true;
    //   }
    //   state.auth = data;
    //   state.loading = false;
    //   state.mess = null;
    // });
    // builder.addCase(loginThunk.rejected, (state, action) => {
    //   console.log("vao day chu");
    //   state.loading = false;
    //   state.mess = "Sai tên đăng nhập hoặc mật khẩu !";
    // });
  },
});

export const { bankAction } = bankSlice.actions;
export default bankSlice.reducer;
