import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  createCustomerDeliveryService,
  deleteCustomerDeliveryService,
  getAllCustomerDeliveryService,
  getAllCustomerDeliveryUserService,
  getAllDeliveryService,
  searchCustomerDeliveryService,
  updateCustomerDeliveryService,
} from "../../services/PartnerConnection";

export const getAllCustomerDelivery = createAsyncThunk(
  "getAll/CustomerDelivery",
  async (payload, thunkAPI) => {
    try {
      const response = await getAllCustomerDeliveryService(payload);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const searchCustomerDelivery = createAsyncThunk(
  "search/CustomerDelivery",
  async (payload, thunkAPI) => {
    try {
      const response = await searchCustomerDeliveryService(payload);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const getAllCustomerDeliveryUser = createAsyncThunk(
  "getAllUser/CustomerDeliveryUser",
  async (payload, thunkAPI) => {
    try {
      const response = await getAllCustomerDeliveryUserService(payload);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const getAllDelivery = createAsyncThunk(
  "getAllUser/getAllDelivery",
  async (payload, thunkAPI) => {
    try {
      const response = await getAllDeliveryService(payload);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const createCustomerDelivery = createAsyncThunk(
  "create/CustomerDelivery",
  async (payload, thunkAPI) => {
    try {
      const response = await createCustomerDeliveryService(payload);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const updateCustomerDelivery = createAsyncThunk(
  "update/CustomerDelivery",
  async (payload, thunkAPI) => {
    try {
      const response = await updateCustomerDeliveryService(payload);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const deleteCustomerDelivery = createAsyncThunk(
  "delete/CustomerDelivery",
  async (Id, thunkAPI) => {
    try {
      const response = await deleteCustomerDeliveryService(Id);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
