import { Button, Result } from "antd";
import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";

const NotFoundScreen = () => {
  const navigate = useNavigate();
  const handleGoToHome = useCallback(() => navigate("/"), []);
  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={
        <Button type="primary" onClick={handleGoToHome}>
          Trở về trang chủ
        </Button>
      }
    />
  );
};

export default NotFoundScreen;
