import { createSlice } from "@reduxjs/toolkit";
import { changeStateValues } from "../../utils";
import {
  createDepartmentThunk,
  deleteDepartmentThunk,
  getDepartmentThunk,
  getStatusThunk,
  updateDepartmentThunk,
} from "./departmentThunk";
const initialState = {
  departments: [],
  status: [],
  isToggle: false,
  pagination: {},
  isShowModalImages: false,
  isShowModalVideo: false,
  departmentSelected: null,
  loading: false,
};

const departmentSlice = createSlice({
  name: "department",
  initialState,
  reducers: {
    toggleDrawer: changeStateValues,
    toggleModalImages: changeStateValues,
    toggleModalVideo: changeStateValues,
  },
  extraReducers: (builder) => {
    builder.addCase(getDepartmentThunk.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getDepartmentThunk.fulfilled, (state, action) => {
      const { payload } = action;
      const data = payload?.data?.data ?? [];
      const pagination = payload?.data?.pagination ?? [];
      state.loading = false;
      state.departments = data;
      state.pagination = pagination;
    });
    builder.addCase(getDepartmentThunk.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(createDepartmentThunk.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(createDepartmentThunk.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(createDepartmentThunk.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(updateDepartmentThunk.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(updateDepartmentThunk.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(updateDepartmentThunk.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(deleteDepartmentThunk.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(deleteDepartmentThunk.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(deleteDepartmentThunk.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getStatusThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.status =
        action?.payload?.data?.data.map((item) => {
          return { value: item?.id, label: item?.statusName };
        }) ?? [];
    });
  },
});

export const { toggleDrawer, toggleModalImages, toggleModalVideo } =
  departmentSlice.actions;
export default departmentSlice.reducer;
