import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  createGroupUserService,
  createPermissionGroupService,
  createPermissionService,
  deleteGroupUserService,
  deletePermissionGroupService,
  deletePermissionService,
  getAllGroupUserService,
  getAllPermissionGroupService,
  getAllPermissionService,
  getByNamePermissionService,
  updateGroupUserService,
  updatePermissionGroupService,
  updatePermissionService,
} from "../../services/storeManager";

export const getAllPermission = createAsyncThunk(
  "Permission/GetAll",
  async (payload, thunkAPI) => {
    try {
      const response = await getAllPermissionService(payload);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const getByNamePermission = createAsyncThunk(
  "Permission/getByName",
  async (payload, thunkAPI) => {
    try {
      const response = await getByNamePermissionService(payload);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const createPermission = createAsyncThunk(
  "Permission/create",
  async (payload, thunkAPI) => {
    try {
      const response = await createPermissionService(payload);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const updatePermission = createAsyncThunk(
  "Permission/update",
  async (payload, thunkAPI) => {
    try {
      const response = await updatePermissionService(payload);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const deletePermission = createAsyncThunk(
  "Permission/delete",
  async (Id, thunkAPI) => {
    try {
      const response = await deletePermissionService(Id);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
////////////////////////////////////////////////////////
export const getAllPermissionGroup = createAsyncThunk(
  "PermissionGroup/getAll",
  async (payload, thunkAPI) => {
    try {
      const response = await getAllPermissionGroupService(payload);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const createPermissionGroup = createAsyncThunk(
  "PermissionGroup/create",
  async (payload, thunkAPI) => {
    try {
      const response = await createPermissionGroupService(payload);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const updatePermissionGroup = createAsyncThunk(
  "PermissionGroup/update",
  async (payload, thunkAPI) => {
    try {
      const response = await updatePermissionGroupService(payload);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const deletePermissionGroup = createAsyncThunk(
  "PermissionGroup/delete",
  async (Id, thunkAPI) => {
    try {
      const response = await deletePermissionGroupService(Id);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
//////////////////////////////////////////////////////
export const getAllGroupUser = createAsyncThunk(
  "GroupUser/getAll",
  async (payload, thunkAPI) => {
    try {
      const response = await getAllGroupUserService(payload);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const createGroupUser = createAsyncThunk(
  "GroupUser/create",
  async (payload, thunkAPI) => {
    try {
      const response = await createGroupUserService(payload);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const updateGroupUser = createAsyncThunk(
  "GroupUser/update",
  async (payload, thunkAPI) => {
    try {
      const response = await updateGroupUserService(payload);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const deleteGroupUser = createAsyncThunk(
  "GroupUser/delete",
  async (Id, thunkAPI) => {
    try {
      const response = await deleteGroupUserService(Id);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
